// ====================
// View grid Drupal: field, panel-pane, view-field, ....
// ---
.views-view-grid {
  .views-col {
    float: left;
    padding:{
      @include sizeRem(left, 15);
      @include sizeRem(right, 15);
    }
  }
  &.cols-1 .views-col {
    width: 100%;
  }
  &.cols-2 .views-col {
    width: 50%;
  }
  &.cols-3 .views-col {
    width: 33.33333333%;
  }
  &.cols-4 .views-col {
    width: 25%;
  }
  &.cols-5 .views-col {
    width: 20%;
  }
  .views-row {
    margin:{
      @include sizeRem(left, -15);
      @include sizeRem(right, -15);
    }
    @include clearfix;
    >* {
      @include sizeRem(margin-bottom, 30);
    }
  }
}

// ====================
// View grid Custom
// ---
@include views-grid;

.container-fluid{
    padding: 0;
}

.container{
    padding:{
        @include sizeRem(top, 25);
        //@include sizeRem(bottom, 25);
    }
}

// ====================
// Reset From
// ---
// Fixed Background input when complete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input,
select {
    @include form-default-input;
    outline: none;
    box-shadow: initial;
    &:focus {
        border-color: $border-color;
        border-bottom-color: darken(#cbd2da, 5%);
    }
}

textarea {
    @include form-default-input;
}


input[type="file"] {
    @include sizeRem(height, 45);
    @include sizeRem(padding, 11, 2, 20);
    line-height: 1.42857143;
}

.form-item,
.form-actions {
    margin:{
        @include sizeRem(top, 20);
        @include sizeRem(bottom, 20);
    }
}

.form-select {
    @include sizeRem(height, 40);
    width: 100%;
    @include form-default-input;
}
// .form-select {
//     @include sizeRem(height, 35);
//     @include sizeRem(padding, 11, 2, 20);
//     line-height: 1.42857143;
//     border-radius: 3px;
//     @include input-placeholder{
//         color: $border-color;
//     }
//     border-color: $border-color;
//     width: 100%;
// }

.form-text {
    @include form-default-input;
    @include sizeRem(height, 40);
    width: 100%;
}

.form-submit {
    @include btn;
    &[id*="cancel"] {
      background-color: $button-cancel-color;
      border-color: $button-cancel-color;
    }
    &[id*="delete"] {
      background-color: $button-delete-color;
      border-color: $button-delete-color;
    }
}

.field-type-text,
.form-type-textfield {
    @include form-default-label;
}

input[type=radio],
input[type=checkbox] {
    position: relative;
    @include sizeRem(top, -3);
}


// ====================
// Reset Flex
// ---
.flexslider {
    border: 0 none;
}

// ====================
// Styling for Language dropdown
// ---
.ddsDefault {
  .dd-container {
    width: auto !important;
    @include sizeRem(min-width, 125);
  }
  .dd-select {
    width: auto !important;
    border: 0 none;
    background: transparent !important;
    border-radius: 0;
    label {
      margin: 0;
    }
  }
  .dd-option{
    display: flex;
    align-items: baseline;
  }
  .dd-selected {
    @include sizeRem(padding, 7, 4, 10, 5, 10);
    position: relative;
    @include sizeRem(padding-left, 34);
    color: $link-color;
    &:before {
      content: '';
      @include sizeRem(width, 14);
      @include sizeRem(height, 14);
      @include sizeRem(left, 10);
      @include sizeRem(margin-top, -7);
      position: absolute;
      top: 50%;
      background: url('../images/ico-globe.svg') no-repeat transparent;
      background-size: contain;
    }
    img {
      display: none;
    }
    &:hover,
    &:focus {
      color: darken($link-color, 8%);
    }
  }
  li {
    display: block !important;
    label {
      line-height: 1.4 !important;
      margin: 0;
    }
  }
  .dd-pointer-down {
    border-top-color: $link-color;
  }
  .dd-options {
    background: $bg-color;
    border: 1px solid $bg-color;
    box-shadow: 0 1px 5px rgba($black-color, 0.6);
  }
  .dd-option {
    cursor: pointer;
  }
  .dd-option-selected {
    label {
      color: $link-color;
    }
  }
  .dd-pointer-up {
    border-bottom-color: $link-color !important;
  }
}

// ====================
// Reset Flex
// ---
.flexslider {
  margin-bottom: 0;
  &:hover,
  &:focus {
    .flex-direction-nav {
      .flex-prev,
      .flex-next {
        opacity: 0.5;
        display: block;
      }
      .flex-prev {
        left: 0;
      }
      .flex-next {
        right: 0;
      }
    }
  }
  .flex-direction-nav a {
    display: block;
  }
}

.flex-direction-nav {
  @include container;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 5;
  a {
    @include sizeRem(width, 30);
    @include sizeRem(height, 30);
    margin-top: -15px;
    border-left: 3px solid $white-color;
    border-top: 3px solid $white-color;
    font-size: 0;
    opacity: 0.5;
    display: block;
    &:before {
      display: none;
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .flex-prev {
    transform: rotate(-45deg);
    left: 0;
  }
  .flex-next {
    transform: rotate(135deg);
    right: 0;
  }
}

.flex-control-nav {
  @include sizeRem(bottom, 30);
  z-index: 6;
}

.flex-control-paging li {
  margin: {
    @include sizeRem(left, 5);
    @include sizeRem(right, 5);
  }
  a {
    @include sizeRem(width, 8);
    @include sizeRem(height, 8);
    background: $white-color;
    box-shadow: initial;
    &:hover,
    &:focus {
      background: $link-color;
    }
    &.flex-active {
      margin-left: 2px;
      margin-right: 2px;
      background: $link-color;
      box-shadow: 0 0 0 2px $link-color;
    }
  }
}

// ************************
// Styling for nav tabs
// ************************

#tabs {
  li {
    float: left;
    margin-bottom: -1px;
    font-family: $fontstack-gotham;
    font-size: 0.8rem;
    font-weight: 500;

    a {
      display: block;
      margin-right: 2px;
      line-height: 1.25;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
      padding: 10px 15px;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {
        background-color: $neutral-lightgrey2;
        border-color: $neutral-lightgrey2 $neutral-lightgrey2 $neutral-lightgrey06;
      }
    }

    &.active {
      a {
        color: #555555;
        background-color: #fff;
        border: 1px solid $neutral-lightgrey06;
        // border-bottom-color: transparent;
        cursor: default;
      }
    }
  }
}



/*........tap.....*/
.item-list {
  padding: 0 15px;
  @include sizeRem(margin, 40 , 2 , 0);
    @include sizeRem(padding, 0, 2 , 15);
  .pager {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $fontstack-gotham;
      @include sizeRem(font-size, 14);


    li {
      padding: 0;
      display: inline-block;
    }
  }

}


// .pager-last{
//     display: none !important;
// }
// .pager-previous{
//     display: none !important;
// }

// .pager-first{
//        a{
//         &:before{
//             content: "\f104";
//             left:0;
//             right:auto;
//         }
//     }
// }


// .pager-next,
// .pager-first {
//    a {
//         &:before{
//         }
//     }
// }


// .pager-last {
//   a {
//     &:before {
//       content: "\e659";
//     }
//   }
// }


// .pager-first a,
// .pager-last a,
// .pager-next a,
// .pager-previous a {
//   font-size: 0;
//   padding:0 !important;
//   border: none !important;
//   background: transparent;
//   position:relative;

//   &:before{
//     position:absolute;
//     content: "\e658";
//     font-family: $fontstack-icomoon;
//     // top:-20px;
//     // right:-15px;
//     font-size: 1.2rem;
//     color: $body-color;
//     background:$white-color;
//     // border-radius: 3px;
//     border:1px solid $border-color;
//     width: 32px;
//     height: 32px;
//     line-height: 30px;
//     top:-22px;
//     right:-32px;
//     text-align: center;
//   }
// }

// .pager-first a:before{
//     left: -32px;
//     right:0 !important;
// }
// .pager-first{
//     margin-left: 34px !important;
// }
// .item-list ul.pager li.pager-current,
// .pager-item a {
//     background:$white-color !important;
//     color:#767575  !important;
//     // border-radius: 3px;
//     border-radius: 0 !important;
//     padding: 0 !important;
//     // border-radius:4px  !important;
//     width: 32px;
//     height: 32px;
//     border:1px solid $border-color !important;
//     line-height: 30px;
//     text-align: center;
//     display: inline-block;
// }
// .item-list ul.pager li.pager-current {
//     color:$white-color  !important;
//     background:$blue-color-read-more  !important;
//     border:none !important;
// }
// .item-list ul.pager li {
//     @include sizeRem(margin, 3, 2 , 2);
// }

.item-list {
  ul.pager {
    li {
      margin-left: 0.3rem;

      a,
      &.pager-current {
        border: 1px solid $border-color;
        border-radius: 0 !important;
        padding: 0.4rem 0.6rem;
      }

      &.pager-current {
        background-color: $border-color;
      }
    }
  }
}



//....table....................
table{
  color: $neutral-black;
  background-color: $neutral-white;
  width: 100%;
  display: block;
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 0px 20px;
  thead tr{
    background: transparent;
    &:hover{
      background:  transparent !important;
    }
  }
  tr{
    background-color: $white-color !important;
    border-bottom: 1px solid $border-color !important;
    &:last-child{
      border-bottom: none !important;
    }
  }
  td,th{
    padding: 10px 10px 10px 0;

    // &:last-child{
    //  border-bottom: none;
    // }
  }
  th{
    padding: 18px 0px 10px 0px;
  }
  tbody{
    border-top: 1px solid $border-color;
    td{
      &:first-child{
        // @include wp-font(font-medium);
      }
      &:last-child{
        border-bottom: none;
      }
    }
    td.views-field-title, td.views-field-name, td.views-field-changed{
      @include sizeRem(font-size, 14);
      // @include wp-font(font-body);
      line-height: 1.33;
      font-style: initial;
      a{
              display: block;
      }
    }
    tr:hover{
      td{
          background-color: #f6f6f6;
      }
    }
  }
}
.views-table{
  thead{
    th:first-child{
      //width: 40%;
    }
    th{
      width: 50%;
      @include sizeRem(font-size, 14);
      font-family: $fontstack-gotham;
      font-weight: $font-weight-bold;
      // font-style: italic;
      border-bottom: none;
      &:first-child{
        width: 30%;
      }
      &:last-child{
        width: 20%;
        @include sizeRem(padding-left, 50);
      }
    }
  }
  tbody td:last-child{
      @include sizeRem(padding-left, 50);
    }
  @include sizeRem(margin-bottom, 60);
}

.view-personal-edit,
.view-personal-edit-v2 {
  // .views-table{
 //        thead{
  //    th{
  //      width: 50%;
  //      &:first-child{
  //        width: 30%;
  //      }
  //      &:last-child{
  //        width: 20%;
  //        @include sizeRem(padding-left, 50);
  //      }
  //    }
  //  }
    // tbody td:last-child{
    //  @include sizeRem(padding-left, 50);
    // }
  //}

  .views-field-field-status {
    padding: 10px 10px 10px 0;
    color: #000;
    font-family: $fontstack-chronicle-body;
    font-size: 1rem;
  }

  .views-table {
    display: table;
    padding: 0 1rem;
  }

  .views-table thead th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: auto;
    font-family: $fontstack-gotham;

    a {
      font-size: 0.875rem;
    }
  }

  .views-table thead th:first-child,
  .views-table tbody tr td:first-child {
    padding-left: 1rem;
  }

  .views-table thead th:last-child,
  .views-table tbody tr td:last-child {
    width: auto;
    padding-left: 0;
    padding-right: 1rem;
    text-align: right;
  }

  .views-table tbody tr td.active {
    background: transparent;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .views-table tbody tr:hover td.active {
    background-color: #f6f6f6;
  }

  .views-table tbody td.views-field-changed {
    font-size: 1rem;
  }

}
.moveable-icon{
  z-index: 99 !important;
}
#edit-basic{
    .form-submit{
      margin-top: 15px;
    }
}

.view-empty{
  @include sizeRem(font-size, 20);
  font-weight: $font-weight-bold;
  @include sizeRem(margin-top, 50);
  @include sizeRem(margin-bottom, 50);
  text-align: center;
}
//..............



// ****************************************
// buttons and call to action link styles
// ****************************************

.btn {
  // display: inline-block;
  // font-weight: 500;
  // text-transform: uppercase;
  // overflow: hidden;
  // border-radius: 0;
  // border: 0;
  // font-size: 0.85rem;
  // margin-bottom: 0;
  // text-align: center;
  // vertical-align: middle;
  // cursor: pointer;
  // background-image: none;
  // border: 1px solid transparent;
  // white-space: nowrap;
  // padding: 6px 12px;
  // line-height: 1.42857143;
  // // border-radius: 4px;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}

.btn-calltoaction {
  @include btn;
  // color: $neutral-white;
  // background-color: $vibrant-blue;
  // border: 0;
  // border-radius: 0;
  // font-weight: 500;
  // text-transform: uppercase;
  // font-family: $fontstack-gotham;

  // &:hover,
  // &:active,
  // &:focus {
  //   color: $neutral-white;
  //   background-color: $vibrant-blue-hover;
  // }
}

.btn-plain {
  @include btn-plain;
}

// *************************************************
// Transcription progress SVG (doughnut graph) view
// *************************************************

.pane-transcription-progress-svg {
  .pane-title {
    font-family: $fontstack-gotham;
    font-size: 0.8rem;
    font-style: normal;
    max-width: 6rem;
    line-height: 1.25;
    margin: 0.5rem 0 1rem 0;

    &:after {
      height: 0;
      width: 0;
      margin: 0;
    }
  }

  .view-content {
    text-align: center;
  }
}

// *************************************************
// admin overlays for boxes etc
// *************************************************

// this is the "Cancel" link that appears on some boxes, in the footer etc. when logged in as admin
// it looks horrible, so hide it.
.boxes-box-controls {
  .cancel {
    display: none !important;
  }
}

// Password confirmation.

.form-type-password-confirm {
  .form-type-password {
    position: relative;
    text-align: left;
    width: 100%;

    @media (min-width: $tablet-ht) {
      label {
        width: 200px;
        text-align: right;
      }
    }
  }
  .password-strength {
    @media (min-width: $tablet-ht) {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0.5em;
    }
  }
  .password-confirm {
    margin-top: 0.5em;
  }
}
.page-user .tt-bootstrap-content form {
  .password-suggestions {
    width: 100%;
    max-width: 25em;
    margin: 1em auto;
  }
}
