// ====================
// Header
// ---
#header{
	@at-root{

	}

}
// Header
#name-and-slogan{
	@include sizeRem(font-size, 25);
	font-family: $fontstack-gotham;
	font-weight: $font-weight-medium;

	letter-spacing: 1px;
	text-transform: uppercase;
	h1 {
		@include sizeRem(font-size, 25);
		font-weight: $font-weight-medium;
	}
	a {
		color: $white-color;
	}
	strong{
		font-weight: $font-weight-bold;
	}
	@media (max-width: $tablet-ht - 1px) {
		margin-top: 1.5rem;
		flex: 1 1 auto;
	}
	@media (min-width: $tablet-ht) {
		@include sizeRem(padding-left, 220);
	}
}
.region-header {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (min-width: $tablet-ht) {
		align-self: left;
	}
}
.header {
	background-color: $neutral-black;
	color: $white-color;
	font-family: $fontstack-gotham;
	.navbar-toggle .icon-bar {
		background-color: $white-color;
	}
	.container {
		padding-top: 0;

		display: flex;
		align-items: center;
		flex-direction: row;

		@include sizeRem(height, 10);
		@include sizeRem(min-height, 75);
		width: auto;
		@include sizeRem(padding-left, 15);
		@include sizeRem(padding-right, 15);
		position: relative;
	}
	@media (min-width: $desktop){
		.container{
			@include sizeRem(padding-left, 30);
		}
	}
	@media (min-width: $tablet-ht){
		.block-menu{
			ul{
				display: flex;
				padding: 0;
				margin: 0;
				li{
					margin: 0;
					padding: 0;
					@include sizeRem(margin-left, 10);
					@include sizeRem(margin-right, 10);
					list-style: none;
					a{
						color: $white-color;
						@include sizeRem(font-size, 13);
						font-weight: 400;
						text-transform: uppercase;
						&:hover, &:focus{
							text-decoration: none;
							border-bottom: 2px solid rgba(255,255,255,.7);
    						text-decoration: none;
							color: $white-color;
						}
						&.active{
							text-decoration: none;
							border-bottom: 2px solid rgba(255,255,255,.7);
    						text-decoration: none;
							color: $white-color;
						}
					}
				}
			}
		}
	}
}

.block--slnsw-logo {
	position: absolute;
	left: 0;
	top: 0;
	.boxes-box-content {
		p {
			margin: 0;
		}
		a {
			display: block;
			position:relative;

			&:before {
				content: "";
				background: url('../images/icons/slnsw_logo.svg') no-repeat center center;
				background-size: contain;
				position: absolute;
				left: 20px;
				top: auto;
				background-position: center;
				z-index: 44;
			}
		}

		@media (max-width: $tablet-ht - 1px) {
			a {
				width: 120px;
				height: 32px;
				&:before {
					width: 120px;
					height: 32px;
				}
			}
		}

		@media (min-width: $tablet-ht) {
			a {
				width: 170px;
				height: 75px;

				&:before {
					width: 170px;
					height: 75px;
				}
			}
		}
	}
}

#block-system-user-menu {
	@include sizeRem(margin-left, 20);
	.content .menu li{
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			right: -10px;
			width: 1px;
			height: 12px;
			background: $border-color;
			transform: translateY(-50%);
		}
		&:last-child{
			&:before{
				display: none;
			}
		}
	}
}

#block-block-5 {
	@include sizeRem(margin-left, 20);
	.content ul li{
		position: relative;
		&:before{
			content: "";
			position: absolute;
			top: 50%;
			right: -10px;
			width: 2px;
			height: 12px;
			background: $border-color;
			transform: translateY(-50%);
		}
		&:last-child{
			&:before{
				display: none;
			}
		}
	}
}
