/* skin menu */
.change-skin-menu-wrapper {
    position: fixed;
    left: -188px;
    top: 150px;
    z-index: 500;
    background-color: #fafafa;
    box-shadow: 1px 1px 2px -1px #888;
    -webkit-box-shadow: 1px 1px 2px -1px #888;
    border-radius: 0 0 3px 0;
    -webkit-border-radius: 0 0 3px 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    font-size: 14px;
    .container {
        width: 185px;
        padding: 10px;
    }
    .background,
    .skin-color {
        overflow: hidden;
    }
    .skin-color {
        margin-bottom: 15px;
    }
    /* tag a */
    .change-skin {
        text-align: center;
        border-radius: 0 5px 5px 0;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: #fafafa;
        padding: 11px;
        width: 40px;
        height: 40px;
        box-shadow: 1px 1px 2px -2px #888;
        -webkit-box-shadow: 1px 1px 2px -2px #888;
        color: #666;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        &:hover,
        &:focus {
            color: #333;
        }
        i {
            font-size: 16px;
        }
    }
    strong {
        margin-bottom: 10px;
        display: block;
        font-weight: 600;
    }
    /* color */
    ul {
        padding-left: 0;
        margin-bottom: 0;
    }
    li {
        list-style: none;
        display: inline-block;
        float: left;
        margin: {
            right: 3px;
            bottom: 3px;
        }
    }
    .change-skin-button,
    .change-background-button {
        display: inline-block;
        text-indent: -999em;
        width: 30px;
        height: 30px;
        opacity: 0.7;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
    /*background*/
    .change-background-button {
        border: 1px solid #cdcdcd;
        &:hover,
        &:focus {
            border-color: #7b7b7b;
        }
    }
    /*layout*/
    .layout {
        margin-bottom: 15px;
    }
    .change-layout-button,
    .change-direction-button {
        border-radius: 0;
        -webkit-border-radius: 0;
        padding: 4px 18px;
        background-color: #fff;
        background-image: none;
        min-width: 0;
        height: auto;
        font-size: 14px;
        text-transform: none;
        line-height: 1.42857;
        font-weight: 500;
        border: 1px solid #ccc;
        color: #333;
        &:hover,
        &:focus {
            background: #fff;
        }
        &:first-child {
            border-right: none;
        }
    }
    .change-layout-button.active {
        background-color: #428bca;
        color: #fff;
        border-color: #428bca;
        font-weight: 700;
    }
    .change-direction {
        @include clearfix;
        li {
            float: left;
            margin: 0;
            padding: 0;
            &:first-child {
                border-right: none;
            }
            .change-direction-button {
                border-right: 1px solid #ccc;
                display: inline-block;
            }
            &.active {
                .change-direction-button {
                    background-color: #428bca;
                    color: #fff;
                    border-color: #428bca;
                    font-weight: 700;
                }
            }
        }
    }
    .background {
        margin-bottom: 15px;
    }
}

.fly-out {
    left: 0;
}

[dir="rtl"] {
    .change-skin-menu-wrapper {
        ul {
            padding-right: 0;
        }
        li {
            float: right;
            margin-left: 3px;
            margin-right: 0;
        }
        .change-direction li {
            margin-left: 0;
        }
        .change-layout-button:first-child,
        .change-direction-button:first-child {
            border-left: none;
            border-right: 1px solid #ccc;
        }
    }
}
