// fixes for mobile screen sizes
@media (max-width: $tablet-vt - 1) {
  .page-search-new {
    .tt-bootstrap-banner {
      .container-fluid {
        min-height: auto;

        .pane-page-title {
          width: 100%;

          h1 {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            // font-size: 1.25rem;
          }
        }
      }
    }
  }
}

.pane-page-breadcrumb {
  .breadcrumb-delimiter {
    span {
      span {
        font-size: 0.6875rem;
        margin-left: 0.375rem;
        margin-right: 0.375rem;
      }
    }
  }
}

.pane-funnelback-sort-funnelback-sort {
  width: auto;
  max-width: 100%;   // this is important for mobile, else select dropdown causes a huge horizontal scrollbar

  @media (max-width: $tablet-ht - 1px) {
    background-color: $lightgray1;
    padding: 0.5rem;
  }

  .funnelback-sort {
    position: relative;
    padding: 0;
    background-color: transparent;
    margin: 0;

    @media (min-width: $tablet-ht) {
      margin: 0 2rem 0 0;
      float: left;
    }

    &__header {
      display: block;
      color: $neutral-white;
      background-color: $neutral-black;
      font-family: $fontstack-gotham;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      padding: 1rem;

      @media (min-width: $tablet-ht) {
        @include element-invisible;
      }

      a {
        display: block;
        color: #fff;

        &::after {
          display: block;
          float: right;
          font-family: $fontstack-icomoon;
          content: "\e686";
        }

        &.collapsed {
          &::after {
            content: "\e681";
          }
        }
      }
    }

    &__select {
      display: none;
      @media (min-width: $tablet-ht) {
        display: block;
        float: left;
        margin-bottom: 1rem;
        border: 1px solid $neutral-black;
      }
    }

    &__radios {
      @media (min-width: $tablet-ht) {
        display: none;
      }

      label {
        display: block;
        border-bottom: 1px solid #eaeaea;
        padding: 0.8em;
        margin: 0.2rem 0 0 0;
        background-color: #fff;
        font-family: $fontstack-gotham;
        font-weight: 300;

        input {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.funnelback-prefilter {
  width: auto;
  max-width: 100%;   // this is important for mobile, else select dropdown causes a huge horizontal scrollbar
  float: left;
  margin: 0 2rem 0 0;

  @media (max-width: $tablet-ht - 1px) {
    background-color: $lightgray1;
    padding: 0.5rem;
    margin: 0;
    float: none;
  }

  &__header {
    color: $neutral-white;
    background-color: $neutral-black;
    font-family: $fontstack-gotham;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    padding: 1rem;

    @media (min-width: $tablet-ht) {
      @include element-invisible;
    }

    a {
      display: block;
      color: #fff;

      &::after {
        display: block;
        float: right;
        font-family: $fontstack-icomoon;
        content: "\e686";
      }

      &.collapsed {
        &::after {
          content: "\e681";
        }
      }
    }
  }

  a[data-funnelback-facet-prefilterdropdown-label] {
    float: left;
    display: block;
    clear: both;
    width: auto;
    margin-bottom: 1rem;
    padding: 0.3rem 1rem;
    background-color: $neutral-lightgrey15;
    font-family: $fontstack-gotham;
    font-weight: $font-weight-bold;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    color: $neutral-black;

    @media (max-width: $tablet-ht - 1px) {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      background: $neutral-lightgrey1;
      cursor: pointer;
    }

    &:after {
      content: '\00d7';
      color: Red;
      font-weight: normal;
      margin-left: 0.4rem;
      font-size: 1.5rem;
      vertical-align: middle;
    }

  }

  &__select {
    display: none;
    @media (min-width: $tablet-ht) {
      display: block;
      float: left;
      margin-bottom: 1rem;
      border: 1px solid $neutral-black;
    }
  }

  &__inner {
    @media (min-width: $tablet-ht) {
      display: none;
    }

    font-family: $fontstack-gotham;
    font-size: 0.9rem;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    label {
      display: block;
      border-bottom: 1px solid #eaeaea;
      padding: 0.8em;
      margin: 0.2rem 0 0 0;
      background-color: #fff;
      font-family: $fontstack-gotham;
      font-weight: 300;

      input {
        margin-right: 0.5rem;
      }
    }
  }
}

.panel-pane {
  &.pane-funnelback-search-pager {
    margin: 3rem 0;

    @media (min-width: $tablet-ht) {
      margin: 3rem 0;
      float: left;
    }
  }

  &.pane-funnelback-sort-funnelback-sort {
    margin: 3rem 0;

    @media (min-width: $tablet-ht) {
      margin: 3rem 0;
      float: left;
    }
  }

  &.pane-funnelback-search-results {
    float: none;
    clear: both;
  }
}

body.page-search {
  .tt-bootstrap-content-columns__column-first {
    @supports (display: flex) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @media (min-width: $tablet-ht) {
        flex-direction: row;
      }

      .panel-pane {
        &.pane-funnelback-search-pager {
          margin: 1rem 0 0;
          flex: 1 1 auto;

          @media (min-width: $tablet-ht) {
            margin: 1rem 0 0;
          }

          .funnelback-pager {
            li {
              margin-bottom: 0;
            }
          }
        }

        &.pane-funnelback-sort-funnelback-sort {
          margin: 1rem 0 0;
          flex: 0 0 auto;

          @media (min-width: $tablet-ht) {
            margin: 1rem 0 0;
          }

          .funnelback-sort {
            margin: 0;

            .funnelback-sort__select {
              margin: 0;
            }
          }
        }

        &.pane-funnelback-search-results {
          flex: 1 1 100%;
          margin: 0;
        }
      }
    }
  }
}

.funnelback-facets,
.funnelback-cart {
  // border: 1px solid $border-color;
  background-color: $lightgray1;
  padding: 0.5rem;

  .facet-header,
  .funnelback-cart__header {
    color: $neutral-white;
    background-color: $neutral-black;
    font-family: $fontstack-gotham;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    padding: 1rem;
  }

  .facet-body,
  .funnelback-cart__inner {
    font-family: $fontstack-gotham;
    font-size: 0.9rem;

    ul {
      text-indent: 0em;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        border-bottom: 1px solid $lightgray1;
        padding: 0.8em;
        margin: 0.2rem 0 0 0;
        background-color: $neutral-white;

        &:lastchild {
          border: 0px;
        }

        input {
          top: 0;
          float: left;
          margin-right: 0.5rem;
        }

        a {
          display: block;

          .facet-item-label {
            // float: left;
          }

          .facet-item-count {
            float: right;

            i {
              font-style: normal;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

// "My List" Funnelback cart specific overrides
.funnelback-cart {
  .funnelback-cart__inner {
    .item-list {
      margin: 0;
      padding: 0;

      ul {
        li {
          // list-style: decimal;
          // list-style-position: inside;
          font-size: 0.8rem;

          a {
            color: $neutral-black;

            .button-text {
              float: right;
              display: inline-block;
              margin: 0.2rem 0 0.2rem 0.5rem;
              // padding: 0.3rem 0.8rem;
              color: $neutral-black;
              // background-color: $neutral-lightgrey17;
              // border: 1px solid $neutral-black;
              text-transform: uppercase;
              font-size: 0.7rem;

              .inner-text {
                display: none;
              }

              .delete-symbol {
                color: $neutral-black;
                font-weight: normal;
                font-size: 1.5rem;
                vertical-align: middle;
              }
            }

            &:hover,
            &:focus,
            &:active {
              text-decoration: underline;
            }
          }

          .funnelback-cart__item__added-timestamp {
            display: block;
            margin-top: 0.2rem;
            font-size: 0.7rem;
            text-transform: uppercase;
            color: $neutral-midgrey05;
          }
        }
      }
    }

    .list-empty {
      padding: 1rem;
    }
  }

  .funnelback-cart__footer {
    padding: 1rem;
    font-family: $fontstack-gotham;
    color: $neutral-lightgrey1;

      a {
        display: inline-block;
        font-weight: $font-weight-bold;
        font-size: 0.85rem;
        text-transform: uppercase;
        margin: 0.4rem;

        .delete-symbol {
          color: Red;
          margin-left: 0.2rem;
          font-weight: normal;
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }
  }
}

.pane-content {

  .form-item-funnelback-search-field {

    // at larger screen sizes, make search box match width of the search results
    @media (min-width: $tablet-vt) {
      width: 66.66666667%;
    }

     input.form-text {
      width: calc(100% - 8rem);
      float: left;
      height: 2.3rem;
      border: 1px solid $neutral-black;
      margin-right: 1rem;

      &::placeholder {
        color: $neutral-midgrey075;
      }
    }
  }

  input.form-submit {
    width: 7rem;
    height: 2.3rem;   // match height of the search input box
  }

  #funnelback-summary {
    margin: 1.5em 0 2em 0em;

    h2 {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 1.7rem;
      // font-style: italic;

      @media (max-width: $tablet-vt - 1) {
        font-size: 1.3rem;
      }

      .search-query {
        font-style: italic;
      }
    }

    .search-result-count {
      font-size: 0.8rem;
      text-transform: uppercase;
      font-family: $fontstack-gotham;
    }
  }

  #funnelback-facets-breadcrumb {
    margin: 2em 0;
    font-family: $fontstack-gotham;
    font-size: 1em;

    .list-inline>li {
      display: inline-block;
      padding-right: 5px;
      padding-left: 5px;
      margin: 0.4em 0px;
    }

    .facets-applied {
      a {
        display: inline-block;
        padding: 0.3rem 1rem;
        background-color: $neutral-lightgrey15;
        font-weight: $font-weight-bold;
        border: 0;
        border-radius: 0;
        text-transform: uppercase;
        color: $neutral-black;

        &:hover,
        &:focus,
        &:active {
          background: $neutral-lightgrey1;
        }

        .delete-symbol {
          color: Red;
          font-weight: normal;
          margin-left: 0.4rem;
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }
    }

    a.clear-all-filters {
      display: inline-block;
      clear: both;
      margin: 1em;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: $neutral-black;

      &:hover {
        color: $neutral-darkgrey2;
      }
    }
  }

  .funnelback-result {
    clear: both;
    border: 1px solid $neutral-lightgrey1;
    margin: 1rem 0rem;
    padding: 1rem;

    h3 {
      font-family: $fontstack-gotham;
      margin: 10px 0px;

      a {
        color: $neutral-black;

        &:hover {
          text-decoration: underline;
        }
      }

      div {
        display: inline;
      }
    }

    .funnelback-result-thumbnail {
      float:left;
      margin: 0 1rem 0.7rem 0;
      width: 4rem;

      @media (min-width: $tablet-vt) {
        width: 6rem;
      }
    }

    .funnelback-result-content {
      float: right;
      width: calc(100% - 5rem);

      @media (min-width: $tablet-vt) {
        width: calc(100% - 7rem);
      }

      .inline-tag {
        font-family: $fontstack-gotham;
        font-weight: 700;
        font-size: 0.7rem;
        text-transform: uppercase;
        letter-spacing: .1em;
        margin: 0 .5em .5em 0;
        padding: .375em 1em;
        line-height: 1.4rem;
        border-bottom: 0 none;
        -webkit-transition: all .2s;
        transition: all .2s;
        text-decoration: none;
        color: $neutral-darkgrey2;
        background-color: $neutral-lightgrey15;

        &:hover,
        &:focus,
        &:active {
          background: $neutral-lightgrey1;
        }
      }

      .funnelback-cart-item-button {
        float: right;

        .button-text {
          text-transform: uppercase;
          font-family: $fontstack-gotham;
          font-size: 0.7rem;

          .delete-symbol {
            margin-left: 0.2rem;
            color: Red;
            font-weight: normal;
            font-size: 1.5rem;
            vertical-align: middle;
          }
        }

        .slnsw-icon {
          margin-left: 0.2rem;
          vertical-align: middle;
          color: $neutral-black;
          font-size: 0.8rem;
        }
      }

      .content-string {
        margin-bottom: 1rem;
      }

      .transcription-progress,
      .completion-percentage {
        color: $neutral-midgrey05;
        font-family: $fontstack-gotham;
        font-size: 0.8rem;
        font-weight: $font-weight-medium;

        .value {
          font-weight: $font-weight-medium;
        }
      }
    }
  }

  .funnelback-pager {
    font-family: $fontstack-gotham;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 0.85rem;

    @media (max-width: $tablet-vt - 1) {
      margin: 1rem 0;
    }

    // @media (min-width: $tablet-vt) {
    //   line-height: 2.5;
    //   text-align: left;
    // }

    li {
      margin-left: 0.3rem;
      margin-bottom: 1rem;
      display: inline-block;

      &.pager-current {
        border: 1px solid #e3e7e8;
        padding: 0.4rem 0.6rem;
        background-color: #e3e7e8;
      }

      a {
        border: 1px solid #e3e7e8;
        padding: 0.4rem 0.6rem;
        transition: all 0.3s ease-out;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          background-color: #eee;
        }
      }
    }

    .pager-current {
      font-weight: $font-weight-bold;
    }

  }

}

.funnelback-cart__item-detail {
  clear: both;

  img {
    float: right;
  }

  &--table {
    width: 100%;
    border: 0 none;
  }

  &__cell {
    width: 100%;

    &--image {
      width: 0%;
    }
  }
}

// styles specific to "Send My List by email" page
.page-funnelback-cart-email {
  .main-content {
    @media (min-width: $desktop) {
      width: 1170px;
    }

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

// homepage specific style overrides
body.front {
  .tt-bootstrap-content {
    .pane-funnelback-search-form {
      margin-top: 1rem;
      margin-bottom: 5rem;

      .form-item-funnelback-search-field {
        // at larger screen sizes, FB search box should be centered
        @media (min-width: $tablet-vt) {
          margin: 1rem auto;
        }
      }
    }
  }
}

// Back to search link
.panel-pane.pane-funnelback-refer-search-link {
  @include container;

  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;

  .tt-bootstrap-banner & {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .funnelback-refer__back-to-search-results {
    color: $neutral-black;
    font-family: $fontstack-gotham;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;

    &::before {
      display: inline-block;
      margin-right: 0.5em;
      font-family: $fontstack-icomoon;
      content: "\e659";
    }
  }
}

// No results message block
#funnelback-results-page {
  .search-no-results {
    ul,
    ol {
      list-style-type: disc;
    }
  }
}
