// ====================
// Block list: block-system, block-block, block-views, block-[name of modules], ....
// ---
.block{
	@at-root{
		// Block menu
		// Block highligh
		// ...
	}
}

// ====================
// Panel List: panel-pane, panel-[field], panel-[class], ...
// ---
.panel-pane{
	margin:{
		@include sizeRem(top, 15);
		@include sizeRem(bottom, 15);
	}
	@at-root{
		// Panel a
		// Panel b
		// ...
	}
}

// Block Banner
// .panel-tt-bootstrap-landing-banner,

/*..............Css Tren IE...................*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	selector { property:value; }
	.tt-bootstrap-banner .container-fluid{
		@include sizeRem(height, 1);
	}
}

/*..............Css Tren IE...................*/

// homepage: the big h1 with background banner image
.front .tt-bootstrap-banner {
	.pane-page-title {
		h1 {
		  color: $white-color;
		  font-family: $fontstack-gotham;
		  text-shadow: 0 0 15px $neutral-black, 0 0 5px $neutral-black;
		}
	}
}

.tt-bootstrap-banner{
	position: relative;
	overflow: hidden;
	img{
		width: auto;
		max-width: inherit;
		-webkit-filter: grayscale(50%);
		filter: grayscale(50%);
		// opacity: 0.5;
		// mix-blend-mode: screen;
	}
	.container-fluid{
		display: flex;
		flex-wrap: nowrap;
		@include sizeRem(min-height, 150);
		align-items: center;
		justify-content: center;
		flex-direction: column;

	}
	.panel-pane{
		// @include flex-width-col-xs(100%);
		margin-top: 0;
		margin-bottom: 0;
	}
	.pane-block-img{
		position: static;
		margin-top: 0;
		margin-bottom: 0;
		.pane-content{
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		   // position:relative;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				z-index: 5;
				background: rgba($black-color, .4);

			}
		}
	}

	.pane-page-title {
		position: relative;
		z-index: 118;
		@include container;
		@include sizeRem(margin-bottom, 10);
		@include sizeRem(margin-top, 25);
		font-family: $fontstack-gotham;

		h1, h3{
			margin: 0;
			text-align: center;
			@include sizeRem(font-size, 40);
			font-family: $fontstack-gotham;
			font-weight: $font-weight-bold;
			// font-style: italic;
			//color: $black-color;
			text-transform: uppercase;
			//background: rgba($title-color, 1.0);
		}
	}
}

@media(min-width: 1920px){
	.tt-bootstrap-banner {
		.pane-content p{
			width: 100%;
			//display: block;
		}
		img{
			width: 100%;
			max-width: 100%;
		}
	}
}


// ********************************************
// breadcrumb
// ********************************************
.breadcrumb{
	background-color: transparent;
	border-radius: 0;
  font-family: $fontstack-gotham;
	font-weight: $font-weight-medium;
	@include sizeRem(font-size, 11);
	display: flex;
	color: $black-color;
	padding: 0;
	text-transform: uppercase;
	margin-bottom: 0;
	font-size:0;
	//background: rgba($title-color, 1.0);

	a {
		@include sizeRem(font-size, 11);
		position: relative;
		@include sizeRem(margin-left, 6);
		@include sizeRem(margin-right, 6);

		max-width: 500px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;

		&.active{
			color: $color_5;
			white-space: normal;
		}
	}

	span.breadcrumb-delimiter {
		color: $black-color;
		@include sizeRem(font-size, 10)
		display: inline-block;
		vertical-align: top;
	}
}


// homepage: steps 1, 2, 3 etc.
.block-panel-steps{
	ol {
		padding: 0;
		margin: 0;
		display: flex;
		@include sizeRem(margin-left, -25);
		@include sizeRem(margin-right, -25);
	}
	li {
		position: relative;
		list-style: none;
		@include flex-width-col-xs(33.3333%);
		border-right: 1px solid #e3e7e8;
		// @include sizeRem(padding-left, 120);
		// @include sizeRem(padding-right, 25);
		// @include sizeRem(padding-top, 30);
		// @include sizeRem(padding-bottom, 10);
	padding: 1.9rem 3rem 1rem 7.2rem;
		// @include wp-font(font-body);
		// @include sizeRem(font-size, 12);
		@include flex-row;
		flex-direction: column;
		line-height: 1.3;
		color: $title-color;
		counter-increment: myIndex;
		position: relative;
		//text-transform: uppercase;

	p:first-child {
	  min-height: 5rem;
	}

		img{
			display: none;
		}
		&:before {
			content:counter(myIndex) ". ";
			position: absolute;
		left: 0.6rem;
			top: 2rem;
			// @include wp-font(font-body);
			@include sizeRem(font-size, 50);
			line-height: 0.7;
			color: $title-color;
			@include sizeRem(min-width, 77);
			text-align: right;
		}
		&:last-child {
			border-right: 0 none;
		}
		// a{
		// 	display: block;
		// 	border-radius: 3px;
		// 	background-color: $white-color;
		// 	border: solid 1px #e3e7e8;
		// 	@include sizeRem(font-size, 14);
		// 	@include wp-font(font-bold);
		// 	text-align: center;
		// 	text-transform: uppercase;
		// 	line-height: $line-height-default;
		// 	@include sizeRem(padding, 15, 4, 20, 13, 20);
		// 	color: $title-color;
		// 	@include sizeRem(margin-top, 15);
		// 	@include sizeRem(height, 50);
		// 	position: absolute;
		// 	@include sizeRem(bottom, 30);
		// 	@include sizeRem(left, 120);
		// 	@include sizeRem(right, 25);

		// 	&:hover, &:focus{
		// 		color: $white-color;
		// 		background-color: $title-color;
		// 		border-color: $title-color;
		// 	}
		// }
	}
}

// homepage: intro heading and text block at top of page
.block-panel-highlight-text {
	// text-align: center;
	// @include wp-font(font-second-body);
	// @include sizeRem(font-size, 15);
	// line-height: 1.33;
	// color: $title-color;
	@include container;
	@include sizeRem(margin-top, 30);
	@media (min-width: $desktop) {
		@include sizeRem(width, 990);
	}

  h2 {
	font-style: italic;
  }
	// strong{
	// 	@include sizeRem(font-size, 18);
	// 	@include wp-font(font-medium);
	// 	// font-style: italic;
	// 	line-height: 1.1;
	// 	text-align: center;
	// 	color: $title-color;
	// 	display:block;
	// 	@include sizeRem(margin-bottom, 20);
	// }
}

.pane-title {
	@include sizeRem(font-size, 30);
	// @include wp-font(font-three-body);
	font-style: italic;
	// line-height: 0.38;
	text-align: center;
	color: $title-color;
  margin-top: 2rem;
  margin-bottom: 2rem;
	// &:after{
	// 	content: '';
	// 	position: relative;
	// 	@include sizeRem(width, 100);
	// 	@include sizeRem(height, 1);
	// 	opacity: .1;
	// 	//background-color: $title-color;
	// 	@include sizeRem(margin-top, 35);
	// 	//@include sizeRem(margin-bottom, 35);
	// 	display:block;
	// 	margin-left: auto;
	// 	margin-right: auto;
	// }
}

.view-project-gift {
	.views-row {
		@include hov-block-border;
		@include flex-row;
		//margin-bottom: 0;

	}
	.views-field-title,
	.views-field-body,
	.views-field-field-percentage-unlocked {
		@include sizeRem(margin, 10, 4, 20, 15, 20);	
	}
	.views-field-title {
		@include sizeRem(margin-bottom, 4);
		font-family: $font-header;
	}
	.views-field-body {
		// @include sizeRem(font-size, 12);
		line-height: 1.25;
	}
  // .views-field-field-completed-percent{
  // 	color: $neutral-midgrey05;
  //   font-family: $fontstack-gotham;
  // 	@include sizeRem(font-size, 13);
  // 	// @include sizeRem(margin-top, -2);
  // 	margin-top: 0;
  // 	font-weight: $font-weight-medium;
  // 	// position: absolute;
  // 	// bottom: 0;
  // 	// left: 0;
  // 	// right: 0;
  // }
  &:not(.view-project-news){
	.view-inner{
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
	.views-row{
		// @include sizeRem(padding-bottom, 30);
		position: relative;
	}
  }
}

.view-project-news{
	.views-row{
		border: none;
		flex-wrap: nowrap;
		@include sizeRem(margin-bottom, 30);
		//@include flex-col-xs;
		flex-direction: row;
		&:hover{
			border: none;
			box-shadow: none;
		}
	}
	.views-field-title,
	.views-field-body{
		@include sizeRem(margin-left, 30);
	}
	.views-field-title{
		margin-top: 0;
	}
}
.views-new-group {
  @include flex-width-col-xs(50%);
  .views-field-title a {
	position:relative;

		// &:after{
		// 	content: '';
		// 	position: relative;
		// 	@include sizeRem(width, 100);
		// 	@include sizeRem(height, 1);
		// 	opacity: .1;
		// 	background-color: $title-color;
		// 	@include sizeRem(margin-top, 25);
		// 	@include sizeRem(margin-bottom, 25);
		// 	display:block;
		// 	margin-left: 0;
		// 	margin-right: auto;
		// }
  }
}

/*...........detail...............*/
.block-views-search-detail{
	.view-filters{
		@include sizeRem(margin-bottom, 20);
	}
	.views-field{
		strong{
			font-weight: normal;
		}
		p{
			&:nth-child(2){
				margin-bottom: 0;
			}
		}
		//border-bottom: 1px solid $border-color;
	}
	.views-exposed-form .views-exposed-widget{
		width: 50%;
	}
	.views-field-body-value{
		@include sizeRem(margin-bottom, 10);
	}
	.views-row{
			border-bottom: 1px solid $border-color;
			@include sizeRem(margin-bottom, 20);
			@include sizeRem(padding-bottom, 10);

		.views-field-body-value{
			margin-bottom: 5px;
			p{
				margin-bottom: 5px;
			}
		}

		.views-field-title{
			margin-bottom: 15px;
			color:$title-color;
			// text-transform: capitalize;
			// font-style: italic;
			line-height: 1.11;
			font-weight: $font-weight-medium;
			@include sizeRem(font-size, 18);
		}
	}
	.view-content{
		@include sizeRem(margin-top, 50);
	}
}
.view-document-list{
	@include sizeRem(margin-top, 25);
	.views-row{
		@include hov-block-border;
		@include sizeRem(margin-bottom, 30);
		@include flex-row;
		position: relative;
	}

	.views-field{
		@include sizeRem(padding, 0 , 2 , 20);
		.views-label {
			font-weight: $font-weight-bold;
		}
	}

	.views-field-field-collection-image {
		padding-left: 0;
		padding-right: 0;
	}

	.view-header {
		@include sizeRem(font-size, 15);
		font-weight: $font-weight-bold;
		@include sizeRem(margin-top, 20);
		@include sizeRem(margin-bottom, 30);
		// span{
		// 	color: $blue-color-read-more;
		// }
	}
	.view-filters {
		border-top: 1px solid $border-color;
		@include sizeRem(padding-top, 8);
	}
	.views-exposed-form .views-exposed-widget {
		.form-item-field-status-value {
			@include sizeRem(margin-top, 30);
		}
		width: 22.5%;
	}
	.views-exposed-form .views-submit-button {
		width: 10%;
		padding-right: 0;
	}
	.views-field-field-tt-image, .views-field-field-transcript-image {
		padding:0;
		text-align: center;
	}
	.content{
		text-align: center;
	}
	.views-field-field-tt-image.hover, .views-field-field-tt-image {
		a {
			position: relative;
			display: block;
		}
	}
	.locked {
		.views-field-field-tt-image.hover, .views-field-field-tt-image {
			a {
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 100%;
					z-index: 5;
					background: rgba($black-color, .4);
				}
				&:after{
					content: "\f023";
					position: absolute;
					top: 50%;
					left: 50%;
					color:$white-color;
					z-index: 12;
					@include fontawesome;
					font-size: 2rem;
					transform: translate(-50%,-50%);
				}
			}
		}
	}
	.views-field-title, .views-field-field-number {
		@include sizeRem(font-size, 18);
		font-weight: $font-weight-bold;
		span,.field-content{
			display: inline-block;
		}
	}
	.views-field-content-lock-custom-status {
		font-weight: $font-weight-bold;
		display: none;
	}
	.views-field-title {
		line-height: 1.33;
		font-style: normal;
		@include sizeRem(font-size, 16);
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
	&.owf-unlocked {
		.views-row {
			@include sizeRem(padding-bottom, 50);
		}
	}
}
/*...............*/


// "Title", "Sory by", "Order" etc labels on landing page filters
.views-exposed-form {
	label{
		@include sizeRem(font-size, 14);
	font-family: $fontstack-gotham;
		font-weight: $font-weight-medium;
	text-transform: uppercase;
		// font-style: italic;
	}
	.form-text{
	}
	.views-widget-filter-title{

	}
	.form-item-sort-by{
		.form-select{
			padding: 0 10px;
		}
	}
	.views-widget-sort-order,.views-submit-button{
		.form-select{
			padding: 0 10px;
		}
		.form-submit{
			line-height: 15px;
		margin-top: 1.5rem;
			// @include sizeRem(margin-top, 30);
			@include sizeRem(height, 40);
			@include sizeRem(padding, 0 , 2 ,20);
		}
	}
}
/*...............*/
.btn-list{
	text-align: right;
	@include sizeRem(margin-top, -35);
	a{
		@include btn-a;
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
		@include sizeRem(font-size, 14);
		&:hover{
			border: none;
			color: $white-color;
			background:$black-color-title;
		}
	}
}

.panel-container.row{
	@include flex-row;
	flex-wrap: nowrap;
	//border: none;
	border: 1px solid $border-color;
	border-radius: 3px;
	.pane-title{
		@include sizeRem(font-size, 20);
		// @include wp-font(font-title);
		&:after{
			margin-top: 25px;
			margin-bottom: 25px;
			width: 50px;
		}
	}

  // Rick Strahl jQuery resizable vertical splitter
	.splitter{
		width: 9px;
		background-color: #c3c3c3;
	flex: 0 0 auto;
	background-image: url(../../assets/images/jquery.resizable/vsizegrip.png),
		url(../../assets/images/jquery.resizable/vsizegrip.png);
	background-position: center 10px, center calc(100% - 10px);
	background-repeat: no-repeat, no-repeat;
	background-color: #c3c3c3;
	min-height: 200px;
	cursor: col-resize;
	}

  .splitter-horizontal {
	flex: 0 0 auto;
	height: 18px;
	background: url(../../assets/images/jquery.resizable/hsizegrip.png) center center no-repeat #535353;
	cursor: row-resize;
  }

	.sidebar-first{
	}
	.panel-right{
		background: transparent;
		@include sizeRem(padding, 10 , 2 , 20);
		.pane-title{
			@include sizeRem(font-size, 20);
		}
		.tips{
			display: none;
		}
		.form-item{
			label{
				@include sizeRem(font-size, 20);
			}
			.form-required{
				display: none;
			}
		}
		.field-name-field-transcript,.field-name-field-review-notes{}
		.field-name-field-working-notes,
	.field-name-field-status {
			display: block;

			label {
		font-family: $fontstack-gotham;
		@include sizeRem(font-size, 14);
		text-transform: uppercase;
		font-weight: $font-weight-medium;
				display: block;
			}
			.grippie{
				display: none;
			}
		}
		.field-name-body{
			display: block;
			label{
				display: none;
			}
		}

		.form-actions{
			display: block;
			display: flex;
			flex-wrap: wrap;
		}

		.btn-edit {
			@include sizeRem(margin-top, 25);
			a{
			width: 165px;
			display: block;
			border-color: $border-color;
			color: $title-color;
			border:1px solid $border-color;
			min-height: 50px;
			line-height: 50px;
			text-align: center;
			font-weight: $font-weight-bold;
			@include sizeRem(font-size, 16);
			//@include sizeRem(padding, 8, 2, 15);
			@include border-radius-rem(3);
				&:hover,&:focus,&.active{

				// background: $blue-color-read-more;
				// border:1px solid $blue-color-read-more;
				//color: $title-color;
			}
		}
		}

	}
	.panel-left{
		background: #fafcfc;
		@include sizeRem(padding, 10 , 2 , 20);
		.pane-title{
			@include sizeRem(font-size, 20);
		}

	}
	.ckeditor_links{
		display: none !important;
	}
	#edit-delete--2{
		display: none;
	}
	#edit-body-und-0-format-help--2{
		display: none;
	}
	.form-actions {
		margin: 0 -10px;
	}

  // "Save and continue" and "Save and close" etc buttons on Transcribe page screen
	.form-submit {
	@include btn;
		margin: 0 10px 10px 10px;
		flex: 1 1 auto;

		&[value="Cancel edit"] {
	  @include btn-plain;
			margin-top: 10px;
			width: 100%;
		}
	}

	.filter-wrapper .form-item{
		float: none;
		padding: 0 !important;
		.form-select{
			padding: 0 15px;
		}
	}

	.deepzoom-error {
		width: 100%;
		position: relative;
		background: rgba(0,0,0,0.7);
		z-index: 2;
		display: block;
		white-space: normal;
		overflow-wrap: break-word;
		padding: 1rem;

		strong {
			color: #ff9999;
		}

		a {
			color: #ffff99;
		}
	}

	.deepzoom {
		background: #d9d7d5;
		height: 100vh;            // make deepzoom container use up 100% space, to maximise screen real estate. Note that deepzoom doesn't like % values, so 100% does not work

		div {
			div:nth-child(4){
				left:0 !important;
				> span{
				   opacity: 1 !important;
				}
				span[title="Zoom in"]{
					img{
						width: 40px;
						height: 40px;
					}
				}
			}
		}
	}
	input[id*="edit-lock-btn-"]{
	}
	.pane-node-form-buttons{
		input[id*="edit-release-btn"]{
		}
	}
}
/*.................search..............*/
.pane-user-online, .pane-user-new, .pane-custom-left, .pane-custom-right {
	width: 50%;
	display: inline-block;
	float: left;

	.pane-title{
		text-align: left;
		line-height: 1.33;
		margin-top: 0;
		@include sizeRem(font-size, 20);
		&:after{
			display: none;
		}
	}
	.item-list a{
		color:$title-color;
		&:hover{
			text-decoration: underline;
			// color: $blue-color-read-more;
		}
	}
}
.pane-user-online, .pane-custom-left {
	@include sizeRem(padding-right, 40);
	border-right: 1px solid $border-color;
}
.pane-user-new, .pane-custom-right {
	@include sizeRem(padding-left, 40);
}

.pane-clear {
	clear: both;
}

.pane-google-cse-google-cse{
	label{
		@include sizeRem(font-size, 20);
		// @include wp-font(font-title);
		// font-style: italic;
	}
	.form-text{
		width: 50%;
	}
	form .form-submit{
		@include sizeRem(margin-left, 15, $important: true);
		position: relative;
		@include sizeRem(top, -2);
	}
	.gsc-control-cse{
		padding: 0;
	}

	.gsc-results-wrapper-nooverlay{
		border: 1px solid $border-color;
		background-color: #fafafa;
		@include sizeRem(margin-top, 50);
		@include sizeRem(padding, 20);
		border-radius: 3px;
		box-shadow: 0 1px 3px rgba(0,0,0, .117647);
	}
}

/*...new.........*/
// cach 2 4filed={
	//view-row{
	// 	position:relative;
	//min-height:285px;
	// }
	//view-image{
	// 	position:absolute;
	// 	right:0;
	// 	top:0;
	//height:100%;
	// field-content{
	// 	height:100%;
	// }
	// a{
	// 	display: flex;
	// 	height:100%;
	// 	justify-content: center;
	// 	align-items: center;
	// }
	// }

//}
.view-new-list{
	background: #eee;
	.views-row{
		display: flex;
		&:nth-child(2n+1){
			.views-field-field-image{
				order:2;
			}
			.view-new-inner{
				&:before{
					transform: rotate(0);
					left:100%;
					right:auto;
				}
			}
		}
		.hover{
			a{
				opacity: 0.8;
				img{
					transform: scale(1.2);
				}
			}
		}
	}
	.views-field-title{
		font-style: initial;
		letter-spacing: 0.15px;
		a{
			display: block;
			border-bottom:2px solid rgba(204,204,204, 0);
			transition: border-bottom .8s;
			@include sizeRem(padding-bottom, 3);
			&:hover{
				border-bottom:2px solid rgba(204,204,204, 0.8);
			}
		}
	}
	.views-field-created{
		letter-spacing: 1px;
		font-weight: $font-weight-medium;
	}
	.views-field-field-image{
		width: 50%;
		overflow: hidden;
		margin-bottom: 0;
		a{
			opacity: 1;
			background: rgba(#eee , 0.6);
			display: block;
			width:100%;
			height:100%;
			transition: all .8s ease-out;
			img{
				transition: all .8s ease-out;
			}
			&:hover,&:focus{
				opacity: 0.8;
				img{
					transform: scale(1.2);
				}
			}
		}
		&:hover{
			+ .view-new-inner{
				.views-field-title{
					a{
						border-bottom:2px solid rgba(204,204,204, 0.8);
					}
				}
			}
		}
	}

	.view-new-inner{
		position: relative;
		width: 50%;
		@include sizeRem(padding, 20, 1);
		&:before{
			content:"";
			position:absolute;
			width: 0;
			height: 0;
			z-index: 1;
			border-top: 30px solid transparent;
			border-bottom: 30px solid transparent;
			border-left: 30px solid #eee;
			border-right: 0 solid transparent;
			top: 50%;
			bottom: auto;
			margin-top: -30px;
			transition: all .4s ease 0s;
			transform: rotate(-180deg);
			right: 100%;
			left: auto;
		}
	}
	.pager-next a, .pager-first a{
		font-size: 16px;
		&:before{
			display: none;
		}
		@include  btn-a;
		border-radius: 50px;
	}
	.item-list{
		background: $white-color;
		margin-top: 0;
		@include sizeRem(padding-top, 50);
	}
}

//.comment.............//
.not-front {
	.tt-bootstrap-footer {
		.comment-form{
			display: none;
		}
	}
	&.logged-in{
		.tt-bootstrap-footer {
			.comment-form{
				display: block;
			}
		}
	}
}

.btn-new-detail{
	text-align: center;
}
.btn-new-detail-2{
	text-align: center;
	// height:0;
	// display: none;
	// overflow: hidden;
	// transition: height 0.3s ease-out;
	// .show &{
	// 	height: 30px;
	// 	display: block;
	// }
	// .logged-in &{
	// 	display: none;
	// }
}
.btn-a-lg{
	color: $title-color;
	border-bottom:2px solid rgba($title-color, 0.3);
	&:hover{
		border-bottom:2px solid rgba($title-color, 1);
	}
}
.btn-a-new{
	@include btn-a;
	height: 40px;
	line-height: 40px;
	border-radius: 50px;
	@include sizeRem(font-size, 14);
}
.pane-node-comment-wrapper{
	height: 0;
	display: none;
	overflow: hidden;
	transition: height 0.3s ease-out;
	&.show {
		height: 100%;
		display: block;
	}
}

.pane-form-comment{
	@media (min-width: 768px){
		margin-right: 40%;
	}
}

.comment-form{
	fieldset.collapsible{
		min-height: 50px;
	}
	.form-item{
		margin-top: 0;
		margin-bottom: 15px;
	}
	.form-actions{
		width: 100% !important;
		padding-left:120px !important;
		position: relative;
		.form-submit{
			display: inline-block;
			height: 40px !important;
			width: auto !important;
			margin-right: 15px;
			text-transform: uppercase;
		}
	}
	@media (min-width: 481px){
		.form-item-subject input{
			max-width: 400px;
		}
		label{
			min-width: 120px;
			display: inline-block;
		}
		.form-actions{
			padding-left:120px !important;
		}
		.form-item{
			position: relative;
			padding-left: 120px;
			label{
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		.field-type-text-long{
			padding-left: 120px;
			position: relative;
			label{
				position: absolute;
				left: 0;
				top: 0;
			}
			.form-item{
				padding-left: 0;
				position: static;
			}
		}
		.content{
			.field-type-text-long{
				padding-left: 0;
			}
		}
	}
}

// .comment-wrapper{
// 	margin-right: 50%;
// 	padding-right: 25px;
// 	position: relative;
// 	min-height: 280px;
// }
// .comment-form{
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// }
.comment{
	margin-top: 15px;
	border-top: 1px solid $border-color;
	padding-top: 15px;
	padding-left: 80px;
	position: relative;
	.user-picture{
		position: absolute;
		left: 0;
		top: 15px;
		a{
			display: block;
			width: 40px;
			height: 40px;
		}
	}
	.comment-author{
		display: inline-block;
		&:after{
			content: ', ';
			display: inline-block;
		}
	}
	.submitted{
		display: inline-block;
	}
	.content{
		margin-top: 15px;
		margin-bottom: 20px;
	}
	h4{
		margin-top: 0;
		margin-bottom: 5px;
	}
	ul.inline li{
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
	}

}

.indented{
	margin-left: 60px;
}

.comment-wrapper{
	h3.title{
		border-top: 1px solid $border-color;
		margin-top: 20px;
		padding-top: 20px;
	}
	h2.title{
		@include sizeRem(font-size, 21);
		margin-bottom: 20px;
	}
}

.page-comment-reply{
	.comment-form{
		margin-top: 30px;
	}
}

.progress {
	min-height: 27px;
	background: transparent;
	box-shadow: inherit;
}

.tt-editor-ui--edit-save-prompt--pane {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #ffffcc;
	z-index: 500;
	padding: 1rem;
	text-align: center;
	transition: all 0.2s ease-out;
	transform: translateY(100%);

	&.active {
		transform: translateY(0%);
	}

	button {
		@include btn;
		margin-left: 0.5rem;
	}
}

body.tt-editor-ui--edit-save-prompt--showing {
	padding-bottom: 71px;
}

.tooltip {
	background: #000;
	color: #fff;

	.tooltip-arrow {
		border-width: 5px;
		border-color: #000;
	}

	&[aria-hidden="false"] {
		opacity: 1;
		filter: alpha(opacity=1);
	}

	&[x-placement="right"] {
		.tooltip-arrow {
			border-left-color: transparent;
			border-top-color: transparent;
			border-bottom-color: transparent;
			left: -10px;
		}
	}
	&[x-placement="left"] {
		.tooltip-arrow {
			border-right-color: transparent;
			border-top-color: transparent;
			border-bottom-color: transparent;
			right: -10px;
		}
	}
	&[x-placement="top"] {
		.tooltip-arrow {
			border-left-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			bottom: -10px;
		}
	}
	&[x-placement="bottom"] {
		.tooltip-arrow {
			border-left-color: transparent;
			border-right-color: transparent;
			border-top-color: transparent;
			top: -10px;
		}
	}

	.tooltip-inner {
		padding: 1rem;
	}

	h3 {
		color: #fff;
		font-size: 1.2rem;
		margin: 0 0 0.5rem;
	}
	p {
		margin: 0;
	}
}

// Tabs block
.tabs.primary {
	margin: 0;
	padding: 0;

	@media (max-width: $mobile-md - 1px) {
		display: block;
		li {
			display: block;
			padding: 0;
			a {
				display: block;
			}
		}

		li:not(.active) {
			display: none;
		}

		&[aria-expanded="true"] {
			li {
				display: block;
				&:not(.active) {
					display: block;
				}

				a {
					border-radius: 0;
				}

				&:first-child a {
					border-radius: 0.3125rem 0.3125rem 0 0;
				}

				&:last-child a {
					border-radius: 0 0 0.3125rem 0.3125rem;
				}
			}
		}
	}
}
.button--primary-tabs-visibility {
	@include visual-control-button($direction: down);

	@media (min-width: $mobile-md) {
		display: none;
	}
}

// **************************************
// % percentage completed fields/views
// **************************************
.views-field-field-completed-percent,
.views-field-field-status,
.views-field-field-percentage-unlocked {
	color: $neutral-midgrey05;
	font-family: $fontstack-gotham;
	@include sizeRem(font-size, 13);
	font-weight: $font-weight-medium;
	// margin-top: -2px !important;
	@include sizeRem(margin-bottom, 15);
}
