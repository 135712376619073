// ====================
// Footer
// ====================

.footer-bottom {
  margin-top: 3rem;
  color: $neutral-lightgrey1;
	background: $neutral-black;
  font-family: $fontstack-gotham;
  @include sizeRem(font-size,13);

	.container{
		@include sizeRem(padding-bottom, 20);

    // in the last column with logos, we want things to be right-aligned
    .col-md-4:last-child {
      text-align: right;

      ul.social {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;

        li.social-icon {
          list-style: none;
          margin: 0;
          display: inline-block;
          padding: .1rem;

          .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
          }

          a {
            display: inline-block;
            border-radius: 2rem;
            line-height: 0.8;
            font-size: 2rem;
            margin-right: 0.2rem;

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
            }

            .slnsw-icon,
            [class^="slnsw-icon-"],
            [class*=" slnsw-icon-"] {
              // font-size: 2.4rem;
              font-family: 'icomoon' !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              letter-spacing: 0;
              -webkit-font-feature-settings: "liga";
              -moz-font-feature-settings: "liga=1";
              -moz-font-feature-settings: "liga";
              -ms-font-feature-settings: "liga" 1;
              font-feature-settings: "liga";
              -webkit-font-variant-ligatures: discretionary-ligatures;
              font-variant-ligatures: discretionary-ligatures;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            &.facebook {
              background-color: #3c5b9b;
            }

            .slnsw-icon-facebook:before {
                content: "\e600";
            }

            &.twitter {
              background-color: #2daae1;
            }

            .slnsw-icon-twitter:before {
                content: "\e605";
            }

            &.youtube {
              background-color: #e62117;
            }

            .slnsw-icon-youtube:before {
                content: "\e943";
            }

            &.instagram {
              background-color: #517fa4;
            }

            .slnsw-icon-instagram:before {
                content: "\e602";
            }

            &.flickr {
              background-color: #e5007e;
            }

            .slnsw-icon-flickr:before {
                content: "\e601";
            }

            &.pinterest {
              background-color: #2daae1;
            }

            .slnsw-icon-pinterest:before {
                content: "\e603";
            }

            &.historypin {
              background-color: #e72d92;
            }

            .slnsw-icon-historypin:before {
                content: "\e800";
            }

            &.tumblr {
              background-color: #35465c;
            }

            .slnsw-icon-tumblr:before {
                content: "\e944";
            }
          }
        }
      }
    }

    a.slnsw-logo {
      display: inline-block;
      width: 200px;
      margin: 2rem 0
    }

    a.government-dpe-logo {
      display: inline-block;
      width: 60px;
    }

    h2 {
      color: $neutral-white;
      font-size: 1.5rem;
      font-family: $fontstack-chronicle;
      margin-top: 0;
    }

    a {
      color: $neutral-white;

      &:not(.btn-calltoaction):hover,
      &:not(.btn-calltoaction):focus,
      &:not(.btn-calltoaction):active {
        text-decoration: underline;
      }
    }
	}
}

.footer-top{
	background: $background-color;
	text-align: center;
	@include sizeRem(padding-top, 15);
	@include sizeRem(padding-bottom, 25);
	p{
		margin-bottom: 10px;
	}
	.content{
		color: $white-color;
		@include sizeRem(font-size, 30);
	}
	.button a{
		@include btn-a;
	}
}

// Copyright, privacy, disclaimer etc block at the very bottom
footer.copyright {
	color: $neutral-lightgrey06;
  font-family: $fontstack-gotham;
  background: $neutral-darkgrey2;
  @include sizeRem(font-size,13);
  text-align: center;

  .container {
    padding-top: 1rem;

    ul {
      padding: 0;
      margin: 0 0 1rem 0;

      li {
        display: inline-block;
        margin: 0;
        text-transform: uppercase;

        &:before {
          display: inline-block;
          content: "/";
          color: $neutral-midgrey;
          font-weight: 400;
          margin-left: -.1rem;
          margin-right: 0.7rem;
        }

        &:first-child {
          margin-left: 0;

          &:before {
          content: "";
          margin: 0;
          }
        }
      }
    }

    p {
  		text-transform: capitalize;
  		margin-bottom: 8px;
  	}

  	a {
      color: $neutral-white;
  		padding-right: 10px;

  		&:hover,
      &:focus,
      &:active {
  			text-decoration: underline;
  		}
  	}
  	.col1{
  		position: relative;
  		img{
  			display: none;
  		}
  		&:before{
  			position: absolute;
  			content: "";
  			width: 50px;
  			height: 54px;
  			top: -8px;
  			right: 0;
  		}
  	}
  }
}
