// ====================
// Section, Region, Aside, Article, Node, ....
// ---
.section{
  @at-root{
    // Section Banner
    // Section Filter
    // ....
  }
}

// ====================
// Pages: about us, front, not-front, ....
// ---
.page{
  @at-root{
    // About Us Page
    // Home Page
    // ....
  }
}
// .logged-in,.not-logged-in{
//  .main .tabs.primary{
//    display: none;
//  }
// }
html, body{
  height: auto !important;
  &.overlay-open{
    overflow-y: scroll;
    position:relative;
  }
}
#overlay-container {
  .overlay-modal-background {
    background: rgba(0,0,0,0.7);
  }
  html {
    overflow-y: hidden;
  }
}

.captcha{
  legend{
    border-bottom: none;
    font-weight: $font-weight-bold;
    @include sizeRem(margin-bottom, 15);
  }
  .fieldset-description{
    @include sizeRem(margin-bottom, 10);
  }
}
.front{
  .block-panel-steps{
    margin-top: 0;
  }
  .panel-tt-bootstrap-landing-footer{
    .container{
      @include sizeRem(padding-bottom, 40);
    }
  }
  //.tt-bootstrap-banner{
    //@include sizeRem(margin-bottom, 20);
  //}
  .tt-bootstrap-content{
    .panel-pane{
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .views-table{
    @include sizeRem(margin-bottom, 80);
  }
  .item-list{
    margin-bottom: 60px;
  }
}

// this is the main content area on all pages
.tt-bootstrap-content{
   .container{
     padding-top: 0;
   }
}


// .page-node-page-document{
//  // .tt-bootstrap-content{
//  //    .container{
//  //      padding-top: 0;
//  //    }
//  // }
//  // .pane-node-field-description{
//  //  margin:0;
//  // }
//  .pane-node-body{
//    p{
//      margin-bottom: 0;
//      @include sizeRem(font-size, 16);
//    }
//  }
// }
.page-dashboard {
  .tt-bootstrap-content-top {
    .panel-pane.buttons-links-block {
      text-align: center;
      margin: 0 0 4rem 0;
    }
  }

  .tt-bootstrap-footer .container{
    display: flex;
  }
}
.not-front{
  .tt-bootstrap-banner{
    @include sizeRem(margin-bottom, 30);
  }
  .panel-tt-bootstrap-landing-main-contain{
    strong{
      font-weight: 700;
    }
    .container{
      @include sizeRem(padding-top, 10);
    }
    .panel-pane{
      margin:0;
    }
  }
  .panel-tt-bootstrap-landing-footer{
    .container{
      @include sizeRem(padding-top, 10);
    }
  }
  .tt-bootstrap-content-top{
    //@include sizeRem(margin-top, 40);
    .field-label,
    .field-item{
      color: $title-color;
      min-width: 185px;
      @include sizeRem(font-size, 16);
      font-weight: $font-weight-bold;
    }
    .field-item{
      font-weight: $font-weight-normal;
    }
    .panel-pane{
      margin:0;
    }

    .container {
      @media (max-width: $tablet-ht - 1px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .panel-tt-bootstrap-landing-main-contain{
    .field-name-field-description {
      color: $title-color;
      @include sizeRem(font-size, 16);
      // @include wp-font(font-second-body);
    }
    .field-name-field-description-1 {
      font-weight: $font-weight-medium;
    }
  }

  .search-api-page-results{
    @include sizeRem(font-size, 26);
  }
  .tt-bootstrap-content,
  .tt-bootstrap-banner {
    h2{
      @include sizeRem(font-size, 26);
      // font-style: italic;
      line-height: 1.25;
      // text-align: center;
      color: $title-color;
      font-family: $fontstack-chronicle;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
      //@include wp-font(font-second-black);

      // &:after{
      //  content: '';
      //  position: relative;
      //  @include sizeRem(width, 100);
      //  @include sizeRem(height, 1);
      //  opacity: .1;
      //  background-color: $title-color;
      //  @include sizeRem(margin-top, 35);
      //  @include sizeRem(margin-bottom, 35);
      //  display:block;
      //  margin-left: auto;
      //  margin-right: auto;
      // }
    }

    #google-cse-results-searchbox-form {
      border: 1px solid $border-color;
      border-radius: 3px;
      @include sizeRem(padding, 20 , 1);

      > div {
        display: flex;
        flex-direction: row;
        @media (max-width: $tablet-ht - 1px) {
          flex-direction: column;
        }

        .form-item {
          display: block;
          flex: 1 1 auto;
          margin: 0 0 0 10px;
          @media (max-width: $tablet-ht - 1px) {
            margin: 15px 0 0 0;
          }
          input, select {
            width: 100%;
          }
          &.form-item-query {
            flex: 1 1 67%;
          }
        }

        .form-submit {
          margin: 0 0 0 10px !important;
          align-self: flex-end;
          @media (max-width: $tablet-ht - 1px) {
            margin: 15px 0 0 0 !important;
          }
          flex: 1 1 auto;
        }
      }
    }

    #search-form{
      border: 1px solid $border-color;
      border-radius: 3px;
      @include sizeRem(padding, 20 , 1);
      .form-item,.form-actions{
        display: inline-block;
        margin:0;
      }
      .container-inline{
        @include flex-row;
        flex-wrap: nowrap;
      }
      .form-item{
        width: 80%;
        @include sizeRem(padding-right, 20);
      }

      .form-submit{

        width: 20%;
        margin-top: 24px;
        @include sizeRem(height, 40);
        font-weight: $font-weight-medium;
      }
      .search-advanced{
        margin:15px 0;
        .form-submit{
          width: auto;
        }
      }
    }

    strong{
      // @include wp-font(font-second-bold);
    }
    // .pane-node-body{
    //  p{
    //    @include sizeRem(font-size, 16);
    //    &:nth-child(2){
    //      @include sizeRem(margin-bottom, 25);
    //    }
    //    &:nth-child(4){
    //      @include sizeRem(margin-bottom, 25);
    //    }
    //  }
    // }
  }

  .tt-bootstrap-footer{

    #search-block-form{
      border: 1px solid $border-color;
      border-radius: 3px;
      @include sizeRem(padding, 20 , 1);
      .form-item,.form-actions{
        display: inline-block;
        margin:0;
      }
      .container-inline{
        @include flex-row;
        flex-wrap: nowrap;
      }
    }
    .form-item-search-block-form{
      width: 80%;
    }
    #edit-actions{
      width: 20%;
      @include sizeRem(padding-left, 20);
      .form-submit{
        height: 35px;
        width: 100%;
        font-weight: $font-weight-medium;
      }
    }
  }
  .footer-top{
    @include sizeRem(margin-top, 25);
  }
}
.page-node-page-edit{
  .tt-bootstrap-content .container{
    @include sizeRem(padding-top, 40);
    @include sizeRem(padding-bottom, 40);
  }
}
.page-page-edit{
  height: 100% !important;
  position:relative;
  overflow-y: hidden;
}
.page-node-document-list,.node-type-document{
  .tt-bootstrap-content{
    .container{
      @include sizeRem(padding-top, 10);
      @include sizeRem(padding-bottom, 10);
    }
    .pane-node-field-description{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
.page-user{
  h3,h2 {
    @include sizeRem(font-size, 26);
    font-weight: $font-weight-bold;
    font-family: $fontstack-chronicle;
  }
  .form-required{
    color: $title-color;
  }
  .tt-bootstrap-banner{
    margin-bottom: 0;
  }
  .tt-bootstrap-content{
    background: #fafafa;
    @include sizeRem(padding-top, 40);
    .pane-content{
      // width: 970px;
      @include sizeRem(padding-left, 15);
      @include sizeRem(padding-right, 15);
      margin:0 auto;
    }
    form{
      background: $white-color;
      @include border-radius-rem(3);
      @include sizeRem(padding, 20 , 2 , 70);
      border:1px solid $border-color;
      box-shadow: 0 0 4px rgba(0,0,0,.175);
      label{
        // @include sizeRem(font-size, 20);
        @include sizeRem(margin-bottom, 5);
        font-size: 0.85rem;
        font-family: $fontstack-gotham;
        text-transform: uppercase;
        font-weight: 500;
      }
      .form-item{
        @include sizeRem(margin-top, 25);
        @include sizeRem(margin-bottom, 25);
      }
      .form-text{
      }
      .description{
        @include sizeRem(margin-top, 10);
        @include sizeRem(font-size, 16);
      }
      .forgot-password{
        .pane-content{
          width: auto;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .profile .user-picture{
    float: none;
  }
}

.page-user-1{
  .tt-bootstrap-content{
    @include sizeRem(padding-top, 50);
    @include sizeRem(padding-bottom, 40);
  }
  .tt-bootstrap-content .container{
    background: $white-color;
    @include border-radius-rem(3);
    @include sizeRem(padding, 20 , 2 , 70);
    border:1px solid $border-color;
    box-shadow: 0 0 4px rgba(0,0,0,.175);

  }
}

.page-news{
  .tt-bootstrap-content {
    h2{
      font-style: initial;
    }
  }
}
.node-type-news{
  .tt-bootstrap-content .container{
    @include sizeRem(padding-bottom, 50);
  }
  .pane-1{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .pane-2{
    margin-top: 0px;
    height:0;
    display: none;
    overflow: hidden;
    transition: height 0.3s ease-out;
    &.show {
      height: 30px;
      display: block;
    }
    .logged-in &{
      display: none;
    }
  }
  .pane-node-created{
    @include sizeRem(font-size, 18);
    font-weight: $font-weight-bold;
    @include sizeRem(margin-bottom, 30);
    text-align: center;
    text-transform: uppercase;
    // font-family: "Gotham", sans-serif;
  }
}

.page-comment-reply{
  .main{
    @include sizeRem(padding-top, 40);
    @include sizeRem(padding-bottom, 30);
    .block-system{
      @include container;
    }
  }
}
.page-user-reset{
  .main{
    @include sizeRem(padding-top, 40);
    @include sizeRem(padding-bottom, 20);
    .block-system{
      @include container;
      text-align: center;
      form{
        @include sizeRem(font-size, 18);
      }
    }
  }
}
.page-user-edit{
  fieldset.collapsed{
    height: 30px !important;
  }
  .password-indicator{
    height: 15px;
  }
  .password-parent,.confirm-parent{
    label{
      display: block;
    }
  }
  .tt-bootstrap-content .pane-content{
    @include sizeRem(padding, 30 , 2 , 70);
  }
  .pane-content{

    background: $white-color;
    @include border-radius-rem(3);

    border:1px solid $border-color;
    box-shadow: 0 0 4px rgba(0,0,0,.175);
    label{

      @include sizeRem(font-size, 20);
      @include sizeRem(margin-bottom, 10);
    }
    .form-item{
      @include sizeRem(margin-top, 15);
      @include sizeRem(margin-bottom, 15);
    }
    .form-text{
    }
    .description{
      @include sizeRem(margin-top, 10);
      @include sizeRem(font-size, 16);
    }
    .forgot-password{
      .pane-content{
        width: auto;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
//move block

.logged-in{
  .pane-login{
    display: none !important;
  }
}

.pane-login{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  .btn-new-detail-2{
    font-size: 21px;
    font-weight: $font-weight-medium;
  }
}

.container-fullwidth{
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: $desktop){
    padding-left: 50px;
    padding-right: 30px;
  }
}

// Controls flexbox.
.tt-bootstrap-content-controls {
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 0;
  }

  .pane-page-tabs {
    flex: 1 1 auto;

    #tabs {
      display: flex;
      flex-direction: row;

      @media (max-width: $tablet-ht - 1px) {
        max-width: calc(100% - 48px);
      }

      ul.primary {
        flex: 1 1 auto;
        @media (max-width: $tablet-ht - 1px) {
          margin: 0 5px 0 0;
        }
      }
      .button--primary-tabs-visibility {
        flex: 0 0 auto;
      }
    }
  }

  .pane-tt-editor-ui-lockwidget, .pane-transcription-progress-svg {
    flex: 0 0 auto;
  }

  .pane-page-messages {
    flex: 1 1 auto;
    width: 100%;
  }

  .tooltip {
    font-family: $fontstack-gotham;
  }
}

// Progress SVG widget.
.pane-transcription-progress-svg {
  padding: 0 1rem;
  svg {
    width: 50px;
    height: 50px;

    .pie-graph-background {
      fill: #000;
      stroke: #fff;
      stroke-width: 0.1;
      stroke-linejoin: round;
    }
    .pie-graph-segment--unlocked {
      fill: #66f;
      stroke: #fff;
      stroke-width: 0.1;
      stroke-linejoin: round;
    }
    .pie-graph-segment--complete {
      fill: #0f0;
      stroke: #fff;
      stroke-width: 0.1;
      stroke-linejoin: round;
    }
  }
}


// *******************************************
// View page and Transcribe page screen
// *******************************************
.page--transcript-page--view,
.page--transcript-page--edit {

  .tt-editor-ui--container {
    // margin-top: 3rem;

    .tt-editor-ui--column--navigation {
      color: $neutral-black;

      .pane-content {
        flex-wrap: wrap;

        .view-page-navigation {
          .view-content {

            // override to make the vertical scrollbar appear for the page nav listing results
            .view-content-inner {
              width: 100%;
              right: auto;
            }
          }
        }

        label,
        .tt-page-selected-footer {
          display: flex;
          flex: 0 0 100%;
          flex-direction: column;
          text-align: center;
          margin: 0 auto 0.5rem auto;
          font-size: 0.85rem;
          font-family: $fontstack-gotham;
          text-transform: uppercase;
          font-weight: 500;
        }

        .tt-page-selected-footer {
          margin: 1rem auto 0.5rem auto;
        }
      }
    }

    // padding-top to make room for the "Jump to page" show/hide button
    .tt-editor-ui--column--image,
    .tt-editor-ui--column--navigation {
      padding-top: 1.5rem;
    }

    .tt-editor-ui--column--image,
    .tt-editor-ui--pane--transcript {
      h2 {
        font-size: 0.85rem;
        font-family: $fontstack-gotham;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;
        padding: 0;

        &:after {
          margin: 0;
          padding: 0;
          background-color: transparent;
        }
      }
    }

    // View page: "Current Status: Review" etc
    .field-name-field-status {
      font-family: $fontstack-gotham;
      @include sizeRem(font-size, 14);
    }
  }
}

// Responsive tabs

.panels-responsive-tabs-processed {
  > .item-list {
    @media (min-width: $tablet-ht) {
      margin: 0;
      padding: 0;
    }
  }
}
.resp-tabs-list {
  font-family: $fontstack-gotham;
  @media (min-width: $tablet-ht) {
    display: flex;
  }
  .resp-tab-item {
    padding: 0.6rem 1rem;
    @media (min-width: $tablet-ht) {
      flex: 1 1 auto;
      margin: 0;
    }
    &.resp-tab-active {
      // Ugh only cos the upstream styles force this too
      padding: 0.6rem 1rem !important;
    }
  }
}
.resp-tabs-container {
  .resp-accordion {
    font-family: $fontstack-gotham;
    &.resp-tab-active {

    }
  }
}

// Public status filter forms.
form[id^="views-exposed-form-homepage-available-pages-"] {
  .views-exposed-widgets {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
  }
  .views-exposed-widget {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
  }
  label, .views-widget {
    flex: 0 1 auto;
  }
  label {
    display: inline-block;
    margin: 0;
    padding: 0 10px 0 0;
    @media (max-width: $tablet-ht - 1) {
      display: none;
    }
  }
  .views-submit-button .form-submit {
    margin: 0;
  }
}
