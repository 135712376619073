// 1200
@media (min-width: $desktop) {
    .navbar-toggle, .btn-close {
        display: none;
    }
}

// 1199
@media (max-width: $desktop - 1) {
    .page-user .tt-bootstrap-content .pane-content{
        width: auto;
    }
    .panel-container.row .form-submit[id*="submit"] {
        min-width: 115px;
    }
    .not-front.page-page-edit{
        .tt-bootstrap-banner{
            @include sizeRem(margin-bottom, 60);
        }
        .tt-bootstrap-content{
            @include sizeRem(margin-bottom, 20);
        }
    }

    .not-front.node-type-page{
        .tt-bootstrap-banner{
            @include sizeRem(margin-bottom, 60);
        }
        .tt-bootstrap-content{
            @include sizeRem(margin-bottom, 20);
        }
    }
    // .collapse-btn,.expand-btn{
    //     left: -8px;
    //     top:-60px;
    // }
}

// 992 - 1199
@media (min-width: $tablet-ht) and (max-width: $desktop - 1) {
    .header .block-menu {
        ul li{
            @include sizeRem(margin-left, 7);
            @include sizeRem(margin-right, 7);
            a{
                @include sizeRem(font-size, 11);
            }
        }
    }
    #name-and-slogan{
        @include sizeRem(font-size, 18);
    }
    #block-system-user-menu{
        @include sizeRem(margin-left, 10);
    }
    .block-panel-steps li a{
        @include sizeRem(font-size, 12);
    }
    #block-system-user-menu .content .menu li:before{
        right: -8px;
    }
    .navbar-toggle, .btn-close {
        display: none;
    }
}

// 991
@media (max-width: $tablet-ht - 1) {
    #page {
        padding-left: 0;
        right: 0;
        transition: all 0.5s ease-in-out;
        position: relative;
        margin-right: 0;
    }
    #page.menu-toggled {
        @include sizeRem(right, 250);
        .btn-close {
            display: block;
        }
        .navbar-toggle {
            visibility: hidden;
        }
        #name-and-slogan {
        }
    }
    #page.menu-opening {
        @include sizeRem(right, 250);
        .btn-close {
            display: block;
        }
        .navbar-toggle {
            visibility: hidden;
        }
    }
    #page.menu-closing {
        @include sizeRem(right, 0);
        .navbar-toggle {
            visibility: hidden;
        }
    }
    .navbar-toggle, .btn-close {
        box-shadow: 0 12px 27px rgba(0, 0, 0, 0.15);
        background: transparent;
        border: 0 none;
        cursor: pointer;
        color: $white-color;
        @include sizeRem(width, 48);
        @include sizeRem(height, 48);
        margin: 0;
        .icon-bar {
            background: $link-color;
            position: relative;
            left: calc((48px - 22px) / 2);
        }
        &:hover,
        &:focus,
        &:active {
            background: none;
            .icon-bar {
                background: darken($white-color, 8%);
            }
            color: darken($white-color, 8%);
        }
    }
    .btn-close {
        display: none;
        font-family: $fontstack-icomoon;
        position: absolute;
        @include sizeRem(right, 8);//right
        @include sizeRem(top, 15);
        z-index: 999;
        &:before {
            content: "\e70d";
        }
    }
    .navbar-toggle {
        border: none;
        display: block;
        padding: 0;
        border-radius: 0;
        float: none;
        flex: 0 0 auto;
    }
    #page.menu-toggled .mobile-main-menu {
        right: 0;
        display: block;
    }
    #page.menu-closing .mobile-main-menu {
        @include sizeRem(right, -250);
        display: block;
    }
    .menu-overlay {
        cursor: pointer;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
    }
    .nav {
        .open > a {
            background: none;
            &:hover,
            &:focus {
                background: none;
            }
        }
    }
    .mobile-main-menu {
        background: #fff;
        bottom: 0;
        display: block !important;
        height: 100%;
        overflow-y: auto !important;
        position: fixed;
        @include sizeRem(right, -250);
        @include sizeRem(width, 250);
        top: 0;
        z-index: 1000;
        transition: all 0.5s ease-in-out;
        .container {
            padding: 0;
            position: static;
            width: auto;
        }
        /* main-menu */
        ul {
            padding: 0;
            margin: 0;
            a {
                display: block;
                color: #000;
                padding-left: 0;
                padding-right: 0;
                @include sizeRem(padding-top, 10);
                @include sizeRem(padding-bottom, 10);
                text-decoration: none;
                &:hover,
                &:focus {
                    color: #555;
                }
                &.is-active {
                    color: #555;
                }
            }
            > li {
                border-bottom: 1px solid #ddd;
                display: block;
                float: none;
                list-style: none;
                margin: 0;
                padding: 0;
                &:last-child {
                    border-bottom: none;
                }
                ul {
                    @include sizeRem(padding-left, 20);
                    @include sizeRem(padding-bottom, 5);
                    li {
                        border-bottom: 0;
                        a {
                            padding:{
                                left: 0;
                                right: 0;
                                @include sizeRem(top, 7);
                                @include sizeRem(bottom, 7);
                            }
                        }
                    }
                }
            }
            .expanded {
                > a {
                    position: relative;
                    &:before {
                        // @include nucleo_glyph;
                        // content: '\ea91';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        @include sizeRem(width, 20);
                        @include sizeRem(height, 20);
                        @include sizeRem(margin-top, -10);
                        line-height: 1.3;
                        text-align: right;
                    }
                }
            }
        }
    }
    // end Mobile-menu
    .block-menu{
            ul{
                padding:35px 0 0;
                margin: 0;
                li{
                    margin: 0;
                    padding: 0;

                    list-style: none;
                    a{
                        color: $title-color;
                        @include sizeRem(font-size, 13);
                        @include sizeRem(margin-left, 15);
                        @include sizeRem(margin-right, 15);
                        font-weight: $font-weight-medium;
                        text-transform: uppercase;
                        // &:hover, &:focus{
                        //     //text-decoration: underline;
                        //     color: $blue-color-read-more;
                        // }
                        // &.active{
                        //     color: $blue-color-read-more;
                        // }
                    }
                }
            }
        }
        #block-system-user-menu{
            border-top: 1px solid $border-color;
            margin-left: 0;
            .content{
                ul{
                    padding-top:0;
                }
            }
        }
        #block-block-5{
            border-top: 1px solid $border-color;
            margin-left: 0;
            .content{
                ul{
                    padding-top:0;
                }
            }
        }
    // menu...................
    .header .container{
        @include sizeRem(padding-right, 15);
    }
    .tt-bootstrap-banner .pane-page-title {
        h1,h3{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            width: 60%;
            @include sizeRem(font-size, 20);
        }
    }

    .not-front .tt-bootstrap-banner {
        margin-bottom: 0;
    }
    .not-front .footer-top{
        margin-top: 0;
    }

    .block-panel-steps li{
        @include sizeRem(padding, 120, 4, 25, 90, 25);
        a{
            @include sizeRem(left, 25);
            @include sizeRem(right, 25);
        }
        &:before{
            @include sizeRem(top, 20);
            left:50%;
            transform: translateX(-50%);
        }
    }

    .view-project-news {
        .views-row{
            flex-wrap: wrap;
        }
        .views-field-title,
        .views-field-body{
            @include sizeRem(margin-left, 0, $important:true);
        }
    }
    .views-new-group{
        @include flex-width-col-xs(100%);
        .views-field-title a:after{
            @include sizeRem(margin-top, 15);
            @include sizeRem(margin-bottom, 15);
        }
    }
    .view-project-gift {
        .views-field-title,
        .views-field-body{
            @include sizeRem(margin, 10, 4 ,15 , 15, 15);
        }
        .views-field-title{
            @include sizeRem(margin-bottom, 4);
        }
        .views-field-body{
            @include sizeRem(font-size, 12);
        }
    }

    .view-document-list {
        .views-exposed-form .views-submit-button{
            width: 25%;
            .form-submit{
                width: 100%;
            }
        }
        .views-field{
            @include sizeRem(padding, 0 , 2 , 15);
        }
        .views-field-title{
            @include sizeRem(font-size, 16);
        }
        .views-field-field-tt-image,
        .views-field-field-transcript-image{
            padding: 0;
        }
    }
    .view-document-list .views-exposed-form .views-exposed-widget{
        width: 25%;
    }

    //.....edit
    .panel-container.row {
        flex-wrap: wrap;
        .panel-left{
            width: 100% !important;
            //height: 500px !important;
        }
        .splitter{
            display: none;
        }
    }

    .page-user .tt-bootstrap-content{
        padding-top: 0;
    }

    .resizable > .container-fluid > .row{
        flex-wrap: wrap;
    }
    .resizable {
        .sidebar-first{
            padding-right: 15px;
            padding-left:15px;
            width: 100%;
            &:before{
                display: none;
            }
        }
        .sidebar-second{
            width: 100%;
        }
    }

    .view-new-list .view-new-inner:before{
        margin-left: -1px;
    }
    .view-new-list .views-row:nth-child(2n) .views-fieldset:before{
        margin-right: -1px;
    }

    .views-table thead th:last-child{
        @include sizeRem(padding-left, 5);
    }
    .views-table tbody td:last-child{
        @include sizeRem(padding-left, 5);
    }

    .page-news .tt-bootstrap-content h2{
        margin-top: 0;
    }

    .node-type-news .tt-bootstrap-content .container{
        @include sizeRem(padding-bottom, 20);
    }
    div.password-confirm,.password-strength{
        float: none;
    }

}

// 768 - 991
@media (min-width: $tablet-vt) and (max-width: $tablet-ht - 1) {}
@media(max-width: 768px){
    .block-panel-hightlight-text{
        @include sizeRem(width, 720);
    }
}
// 767
@media (max-width: $tablet-vt - 1) {
    //font-size
    .not-front .tt-bootstrap-content h2{
        line-height: initial;
    }
    .pane-title{
        @include sizeRem(font-size, 30);
    }

    .breadcrumb span{
        &:last-child{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width:300px;
            display: inline-block;
        }
    }
    /*......home.................*/
    .front{
       .tt-bootstrap-content,.tt-bootstrap-footer{
            .container{
                @include sizeRem(padding-top, 10);
                @include sizeRem(padding-bottom, 10);
            }
            .panel-pane{
                margin-bottom: 0;
            }
       }
    }
    .tt-bootstrap-banner {
        .pane-page-breadcrumb,
        .pane-page-title{
            width:700px;
        }
    }

    .block-panel-steps li{
        @include sizeRem(padding, 120, 4, 15, 90, 15);
        a{
            @include sizeRem(left, 15);
            @include sizeRem(right, 15);
        }
    }
    .block-panel-steps ol{
        @include sizeRem(margin-left, -15);
        @include sizeRem(margin-right, -15);
    }
    .block-panel-hightlight-text{
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-right: 0;
        padding-left: 0;

    }

    .view-grid-4-cols .views-row{
        @include flex-width-col-xs(47.36842%);
    }
    .view-document-list .views-row{
        @include sizeRem(margin-bottom, 20);
    }
    .view-project-gift{
        .views-row{
            @include sizeRem(margin-bottom, 20);
            &:last-child{
                //margin-bottom: 0;
            }
        }
        .views-field-field-project-image{
            text-align: center;
        }
    }
    .block-panel-hightlight-text{
        width:auto;
    }
/*.........not........*/
    .views-table thead th:first-child{
        width:50%;
    }

    #footer {
        .row{
            margin: 0;
        }
        .region-panel-footer-1{
            @include flex-width-col-xs(50%);
            @include sizeRem(padding-right, 15);
        }
        .region-panel-footer-2{
            @include flex-width-col-xs(50%);
            @include sizeRem(padding-left, 15);
            .description br{
                display: none;
            }
        }
    }

    .page-user .tt-bootstrap-content form{
        @include sizeRem(padding-left, 30);
        @include sizeRem(padding-right, 30);
    }
    .page-user-1 .tt-bootstrap-content .container{
        @include sizeRem(padding-left, 30);
        @include sizeRem(padding-right, 30);
    }
    .page-user-1 .tt-bootstrap-content{
        padding-bottom: 0;
    }

    .block-views-search-detail .views-exposed-form .views-exposed-widget{
        width: 70%;
    }
    .block-views-search-detail .views-exposed-form .views-submit-button{
        width: 30%;
    }
    .view-project-gift{
        .views-row{
            flex-direction: column;
        }
    }
    .view-project-news .views-row{
        flex-direction: row;
    }
    // .view-new-list .view-new-inner:before{
    //     display: none;
    // }
    .view-new-list {
        min-height: 285px;
        .views-field-field-image a {
            position: relative;
            img{
                max-width: inherit;
                position: absolute;
                transform: translateX(-20%);
            }
        }
    }


     .not-front .tt-bootstrap-content h2:after{
        @include sizeRem(margin-top, 20);
        //@include sizeRem(margin-bottom, 30);
    }
    .page-user-edit .pane-content .description{
        margin-left: 15px;
    }
}
@media (max-width: 700px){
    .btn-list{
        @include sizeRem(margin-top, 15);
        text-align: left;
    }
}
// 640
@media (max-width: $mobile) {
    .front .tt-bootstrap-banner{
        margin-bottom: 0;
    }
    .front .views-table{
        margin-bottom: 50px;
    }
    .block-panel-steps {
        ol{
            display: initial;
        }
        li{
            @include sizeRem(padding-top, 80);
            @include sizeRem(padding-bottom, 90);
            @include flex-width-col-xs(100%);
            border-right:none;
            text-align: center;
            a{
                margin: 0 auto;
                width: 50%;
            }
            &:before{
                top:0;
                letter-spacing: 0px;
                @include sizeRem(font-size, 80);
            }
        }
    }

    .front .tt-bootstrap-footer .container{
        padding-top: 10px;
    }
    .copyright .col1{
        @include sizeRem(padding-right, 80);
    }

    //......table..........
    table th{
        padding: 12px 0px;
    }

    .views-table {
        padding:0 15px;
        .views-field{
            margin-bottom: 0;
        }
        tr{
            display: block;
            padding:0  !important;
            td,th{
                display: block;
                border: none;
            }
            td{
                padding: 5px;
            }
        }
        thead th{
            width:100%;
            //width: 100%;
            display: block;
            margin-bottom: 0;
            border-bottom: 1px solid $border-color;

            &:first-child{
                width:100%;
            }
            &:last-child{
                padding-left: 0;
                border-bottom: none;
            }
            &.views-field-title{
                display: block;
                margin-bottom: 0;

            }
        }
    }
    .view-document-list .views-exposed-form .views-exposed-widget{
        width: 50%;
        &.views-widget-sort-by{
            clear: left;
        }
        &.views-submit-button{
            padding-right: 15px;
            .form-submit{
                margin-top: 20px;
            }
        }
        &.views-widget-sort-order,
        &.views-widget-filter-field_status_value {
            padding-right:0;
        }
    }
}
//550
@media (max-width: 550px){

    .view-grid-4-cols .views-row{
        @include flex-width-col-xs(97.36842%);
    }
    .view-grid-2-cols .views-row{
        @include flex-width-col-xs(97.36842%);
        border-bottom: 1px solid $border-color-un-title;
        padding-bottom: 10px;
        margin-bottom: 30px;
    }
    .view-project-gift{
        .views-row{
            //flex-direction: row;
            border: transparent;
            border-radius: 0;
            border-bottom: 1px solid $border-color-un-title;
            &:hover{
                box-shadow: none;
                border-color: transparent;
                border-bottom: 1px solid $border-color-un-title;
            }
        }
        .views-field-field-project-image{
            text-align: left;
        }
        .views-field-title,.views-field-body,.views-field-field-completed-percent{
            margin-left: 0;
            margin-right: 0;
        }
    }

     .tt-bootstrap-banner .pane-page-title {
        h1,h3{
            width: 40%;
        }
    }

    .item-list .pager{
        flex-wrap: wrap;
    }

}
// 480
@media (max-width: $mobile-md) {
    .not-front .tt-bootstrap-content h2{
        @include sizeRem(font-size, 24);
    }
    #footer .region-panel-footer-1,#footer .region-panel-footer-2{
        @include flex-width-col-xs(100%);
        padding-top: 0;
    }
    #footer .region-panel-footer-2{
        padding-left: 0;
        border-left: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        @include sizeRem(margin-top, 20);
        @include sizeRem(padding-top, 20);
    }
    .footer-logo{
        width: 100%;
        &:before{
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .block-ico-social{
        text-align: center;
    }
    .footer-top{
        padding-top:0;
        padding-bottom: 10px;
        .content{
            @include sizeRem(font-size, 20);
        }
        .button a{
            height: 40px;
            line-height: 40px;
        }
    }

    .block-panel-steps li a{
        width: auto;
        left: 0;
        right: 0;
    }

    .pane-user-online, .pane-custom-left {
        @include sizeRem(padding-right, 15);
    }
    .pane-user-new, .pane-custom-right {
        @include sizeRem(padding-left, 15);
    }

    .view-document-list .views-exposed-form .views-exposed-widget{
        width: 100%;
        padding:15px 0 0;
    }
    .view-document-list .views-exposed-form .views-exposed-widget .form-item-field-status-value {
        margin-top: 5px;
    }
    .view-document-list .views-exposed-form .views-submit-button{
        padding-top: 0;
    }
    //....edit
    .panel-container.row .panel-right .form-actions{
        flex-wrap: wrap;
    }
    .panel-container.row .form-submit{
        width: 100%;
        margin-right: 0;
        min-height: 30px;
        @include sizeRem(margin-bottom, 15);
    }

    .page-user .tt-bootstrap-content form{
        @include sizeRem(padding, 5, 4 ,15 , 5 , 15);
    }
    .page-user .tt-bootstrap-content .pane-content{
        padding-right: 0;
        padding-left: 0;
    }
    .page-user-1 .tt-bootstrap-content .container{
        @include sizeRem(padding-left, 15);
        @include sizeRem(padding-right, 15);
    }
    .page-user-edit .tt-bootstrap-content .pane-content{
        @include sizeRem(padding, 15,1);
    }
    .view-new-list {
        .views-row{
            flex-wrap: wrap;
            @include sizeRem(margin-bottom, 15);
            min-height: auto;
            &:nth-child(2n+1) .views-field-field-image{
                order: initial;
            }
            .views-field-field-image a {
                position: relative;
                img{
                    max-width: 100%;
                    position: static;
                    transform: translateX(0%);
                }
            }
        }

        .view-new-inner ,.views-field-field-image{
            width: 100%;
            &:before{
                display: none;
            }
        }
        .views-field-field-image a img{
            transform: translateX(0%);
            max-width: 100%;
        }
    }

    .comment-form .form-actions{
        padding-left: 0 !important;
    }
}
//420
@media (max-width: 420px){
    .copyright .col1{
        @include sizeRem(padding-right, 80);
    }
    #name-and-slogan{
        @include sizeRem(font-size, 16);
    }
    .pane-title:after{
        @include sizeRem(margin-top, 30);
        @include sizeRem(margin-bottom, 30);
    }
    .not-front .tt-bootstrap-content h2:after{
        //@include sizeRem(margin-top, 30);
        @include sizeRem(margin-bottom, 30);
    }
    #name-and-slogan{
        letter-spacing: 2px;
    }
    .navbar-toggle{
        margin-top: 5px;
        margin-right: 0px;
    }

    .breadcrumb span{
        &:last-child{
            width:220px;
        }
    }

    .page-user .tt-bootstrap-content form label{
        @include sizeRem(font-size, 16);
    }
    .page-user .tt-bootstrap-content form .form-item{
        @include sizeRem(margin-top, 10);
        @include sizeRem(margin-bottom, 10);
    }
    legend{
        font-size: 16px;
    }
    .btn-list a{
        width: 100%;
    }

    .g-recaptcha{
        > div{
            width: 100% !important;
        }
    }
}

@media (max-width: 370px){
    .g-recaptcha{
        > div{
            padding-right: 30px;
            position: relative;
            &:before{
                content: "";
                position: absolute;
                @include sizeRem(height, 76);
                @include sizeRem(width, 100);
                border-radius: 3px;
                background: #f9f9f9;
                border: 1px solid #d3d3d3;
                border-left: none;
                right:0;
                top:0;
            }
        }
    }
}
// 360
@media (max-width: $mobile-sm) {
    .block-views-search-detail .views-exposed-form .views-exposed-widget{
        width: 100%;
        padding-right: 0;
    }
    .block-views-search-detail .views-exposed-form .views-submit-button{
        width: 100%;
        padding-right: 0;
        .form-submit{
            width: 100%;
            margin-top: 10px;
        }
    }
}

// 320
@media (max-width: $mobile-xs) {}
