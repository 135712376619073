// ====================
// View lists : view, view-frontpage, view-view ,view-type-[class], .....
// ---
.views-field{
	@include sizeRem(margin-bottom, 10);
	a{
		color: $link-color;

		&:hover,
    &:focus,
    &:active {
			color: $title-color;
		}
	}
}

// .views-field-title a{
// 	&:hover{
// 		color:$blue-color-read-more;
// 	}
// }

.views-field-field-canonical-id {
	color: $neutral-midgrey05;
	font-family: $fontstack-gotham;
	@include sizeRem(font-size, 13);
	font-weight: $font-weight-medium;
	@include sizeRem(margin-bottom, 5);
}

.views-field-title,
.views-field-name,
.views-field-changed {
	color:$title-color;
	line-height: 1.11;
	font-weight: $font-weight-medium;
	@include sizeRem(font-size, 18);
}
.views-field-body{
	color:$body-color;
}

.resizable {
	> .container-fluid {
		height: 100%;
		> .row {
			height: 100%;
			display: flex;
		}
	}
	.sidebar-first {
		position: relative;
		@include sizeRem(padding-right, 30);
		@include sizeRem(padding-left, 15);
		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			bottom: 0;
			@include sizeRem(right, 5);
			height: 100%;
			background: url('../images/border-zoom.png') no-repeat center right;
			background-size: contain;
			display: block;
			z-index: 10;
			@include sizeRem(width, 25);
		}
		select {
			@include sizeRem(height, 40);
		}
	}
}
.view-zoom-type-list {
	.views-row {
		border-bottom: 1px solid $border-color;
		@include sizeRem(padding-top, 13);
		@include sizeRem(padding-bottom, 13);
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		&:last-child{
			border-bottom: 0;
		}
	}
	.views-field {
		flex-grow: 1;
		margin-bottom: 0;
		text-transform: uppercase;
		@include sizeRem(font-size, 11);
    	font-family: $fontstack-gotham;
		font-weight: $font-weight-bold;
		//text-align: right;
		color: #888888;
		width:100%;
		@include sizeRem(padding-left, 75);
		.field-content{
			display: inline-block;
		}
	}

	.views-field-field-number {
    	font-family: $fontstack-gotham;
		@include sizeRem(font-size, 13);
		font-weight: $font-weight-bold;
		color: $title-color;
		text-align: left;
		text-transform: uppercase;
		width:0;
		padding-left: 0;
		a{
			color: $title-color;
			&:hover, &:focus{
				color: $link-color;
			}
		}
	}
	.views-field-content-lock-custom-status{
		position: relative;
		font-size: 0;
		&:before{
            content: "\f023";
            position: absolute;
            top: -10px;
            bottom:0px;
            right: 0;
            color:#767575;
            @include fontawesome;
            @include sizeRem(font-size, 14);
            transform: translateY(-50%);
        }
	}
	.views-field-field-status {
		white-space: normal;
	}

    .view-header label{
    	color: $title-color;
		  @include sizeRem(margin-top, 25);
    }

	.view-filters{
		@include sizeRem(margin-bottom, 45);
		input[type="submit"][hidden] {
			display: none;
		}
	}
    #tt-page-current-status{
    	width: 100%;
    }
	.views-exposed-form .views-exposed-widget{
		float: none;
		padding: 0;
	}

	.views-exposed-form label{
		color: $title-color;
		@include sizeRem(margin-top, 25);
		display: none;
	}


	@media (min-width: 1200px){
		.view-content{
		    min-height: 500px;
		    height: 100%;
		    width: 100%;
		    overflow: hidden;
		    position: relative;
		}
		.view-content-inner{
			position: absolute;
		    left: 0;
		    top: 0;
		    bottom: 0;
		    right: -17px;
		    z-index: 1;
		    display: block;
		    height: 100%;
		    overflow-x: hidden;
		    overflow-y: scroll;
		}
	}
	@media (max-width: 1199px){
		.view-content{
			overflow-x: hidden;
		 	overflow-y: scroll;
			max-height: 500px;
		}
	}

}

.panel-pane-pager{
	width: 100%;
	.pane-content{
		display: flex;
		@include sizeRem(margin-left, -3);
		@include sizeRem(margin-right, -3);
		align-items: center;
		> *{
			flex-grow: 1;
			@include sizeRem(padding-left, 3);
			@include sizeRem(padding-right, 3);
			&:nth-child(2){
				// flex-grow: 3;
				text-align: center;
			}
		}
		label{
			display: none;
		}
	}
	a{
		color: $title-color;
		&:hover, &:focus{
			color: $link-color;
		}
	}
	#tt-prev{
		font-size: 0;
    text-align: left;
		&:before{
			content: "\e659";
			font-family: $fontstack-icomoon;
      @include sizeRem(font-size, 20);
      font-weight: 600;
			@include sizeRem(margin-right, 3);
		}
	}

	#tt-next{
		font-size: 0;
		text-align: right;
		&:before{
      content: "\e658";
      font-family: $fontstack-icomoon;
      @include sizeRem(font-size, 20);
      font-weight: 600;
			@include sizeRem(margin-left, 3);
		}
	}
}

.pane-block-7{
	position:relative;
	.pane-content a{
		position: absolute;
		content: "";
		right: 0px;
		top: 13px;//25px;
		z-index: 55;
		width: 60px;
		height: 25px;
		background-size: contain;
		img{
			display: none;
		}
	}
}



.full-width-icon {
	width: 33px !important;
  height: 33px !important;
	line-height: 33px;
    //background:$title-color !important;
	//color: $white-color !important;
  font-size: 1.4rem;
	text-align: center;
	right: -1px !important;
	bottom: auto !important;
	top: -1px;
	cursor: pointer;
	background: #111111 !important;
	color: $white-color;
  transition: all 0.3s;


	border-top-right-radius: 3px;
	//background: #dddddd !important;
	// @include border-radius-rem(50);
	// @include box-shadow(0px 0px 3px 1px rgba(17, 17, 17, 0.3));//(0 0 1px 2px rgba($title-color,0.2));
	// @include sizeRem(margin, 10, 2 ,8);
	z-index: 10 !important;

  &:hover,
  &:focus,
  &:active {
    color: $neutral-lightgrey075;
  }
}

.moveable-icon{
	width: 23px !important;
    height: 23px !important;
	line-height: 23px;
    //background:$title-color !important;
	//color: $white-color !important;
	text-align: center;
	cursor: pointer;
	background: #dddddd !important;
	@include border-radius-rem(50);
	box-shadow: 0px 0px 3px 1px rgba(17, 17, 17, 0.3);//(0 0 1px 2px rgba($title-color,0.2));
	@include sizeRem(margin, 10, 2 ,8);
	&:before{
		content: "\f047";//"\f065";
		@include fontawesome;
		color: rgba($title-color,0.8);
		@include sizeRem(font-size, 14);
		transform: rotate(90deg);
	}
}

// expand/collapse toggle button, as used on View page/Transcribe page screens

.collapse-btn {
	@include toggle-button($action: collapse);

	// position: absolute;
  top: -11px;
  left: -6px;
	// top: -10px;
	// left: -50px;
  border-top-left-radius: 3px;
}

.expand-btn{
	@include toggle-button($action: expand);

	// position: absolute;
  top: -11px;
  left: -6px;
	// top: -10px;
	// left: -50px;
  border-top-left-radius: 3px;
}

.panel-container.row{
	overflow: inherit;
	margin-left: 0;
	margin-right: 0;
}

*.panel-left{
	margin-left: 0;
	margin-right: 0;
}
