/*------------------------------------------------------------------
CSS

-------------------------------------------------------------------*/

// Bootstraps
@import "includes/bootstrap";

// Font Awesome
@import "includes/font-awesome";

// SVG
// @import "includes/svg";

// Fonts
@import "base/fonts";

// Core variables and mixins
@import "config/variables";
@import "config/mixins";

// Skins
@import "base/skincolor";
@import "base/skinbackground";
@import "base/skinmenu";

// Base
@import "base/base";                    // basic HTML elements
@import "base/elements";
@import "base/funnelbacksearch.scss";
@import "base/header";
@import "base/footer";

// Partials
@import "partials/view";
@import "partials/block";
@import "partials/page";
@import "partials/responsive";
@import "partials/print";


