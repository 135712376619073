// ===========================
// New Typography variables
// ===========================


// Font stacks
$fontstack-gotham: "Gotham A", "Gotham B", Montserrat, Helvetica, Arial, sans-serif;
$fontstack-chronicle: "Chronicle Display A", "Chronicle Display B", "Crimson Text", "Times New Roman", Times, serif;
$fontstack-chronicle-body: "Chronicle Text G1 A", "Chronicle Text G1 B", "Crimson Text", "Times New Roman", Times, serif;
$fontstack-fontawesome: "FontAwesome";
$fontstack-icomoon: "icomoon";


// ===========================
// New colour variables
// ===========================

// Greys
// -------------------------

$neutral-black:         #000;
$neutral-darkgrey4:     #111;
$neutral-darkgrey3:     #222;
$neutral-darkgrey2:     #333;
$neutral-darkgrey15:    #4d4d4d;
$neutral-darkgrey1:     #555;
$neutral-darkgrey05:    #666;
$neutral-midgrey:       #777;
$neutral-midgrey05:     #999;
$neutral-midgrey075:    #adadad;
$neutral-lightgrey05:   #b3b3b3;
$neutral-lightgrey06:   #bbb;
$neutral-lightgrey075:  #c5c5c5;
$neutral-lightgrey1:    #ccc;
$neutral-lightgrey15:   #ddd; // carousel backgrounds
$neutral-lightgrey17:   #e4e4e4;
$neutral-lightgrey2:    #eee;
$neutral-lightgrey25:   #f2f2f2; // carousel captions and ui widgets
$neutral-lightgrey3:    #f6f6f6;
$neutral-white:         #fff;

// Brand colors
// -------------------------

$vibrant-darkmint:      #64c3af;
$vibrant-mint:          #8fffb6;
$vibrant-yellow:        #fff200;
$vibrant-magenta:       #ed1e79;
$vibrant-orange:        #f68b1f;
$vibrant-orange2:       #ff6000;
$vibrant-cyan:          #00aeef;
$vibrant-blue:          #29abe2;
$vibrant-blue-hover:    #1a8dbe;
$vibrant-green:         #009248;
$vibrant-blue2:         #3399ff;
$vibrant-blue3:         #00aeef;
$vibrant-red:           #dd4b39;
$instagram-blue:        #3f729b;




// =================================================================================================
// BELOW ARE THE LEGACY VARIABLES -- we should phase these out eventually as they are not semantic
// =================================================================================================

// ====================
// TYPOGRAPHY
// ---

$font-body:                 $fontstack-chronicle-body;
$font-header:               $fontstack-chronicle;
// $font-bold:          	'Gotham A','Gotham B',Montserrat ,Helvetica ,Arial ,sans-serif;
// $font-medium:	        'Gotham A','Gotham B',Montserrat ,Helvetica ,Arial ,sans-serif;
// $font-light:	   		'Gotham A','Gotham B',Montserrat ,Helvetica ,Arial ,sans-serif;

$font-weight-normal:		    normal;
$font-weight-bold:	        600;
$font-weight-medium:        500;
$font-weight-light:		      300;

// // Georgia
// $font-second-bold:      'Georgia', sans-serif;
// $font-second-nor:	    'Georgia', sans-serif;

// $font-weight-second-body:		normal;
// $font-weight-second-bold:		bold;

// // Chronicle
// $font-three-body:        'Chronicle Display A','Chronicle Display B','Crimson Text','Times New Roman',Times,serif;
// $font-three-bold:        'Chronicle Display A','Chronicle Display B','Crimson Text','Times New Roman',Times,serif;

// $font-weight-three-body:		300;

// ====================
// Font Size Rem
// Default: 1rem = 16px
// ---
$size-default:		16px;
$line-height-default: 1.42857143;

// ====================
// Colors hex values
// ---
$white:			#fff !default;
$black:			#000000 !default;
$lightgray:		#a7a7a7 !default;
$lightgray1:	#eaeaea	!default;
$darkgray:		#343434 !default;
$blue:			#013ca6 !default;

// ====================
// Colors scheme for Themes
// ---
$white-color:			$white !default;
$black-color:			$black !default;
// $link-color:			#20826a !default;
$link-color:      $instagram-blue !default;
$body-color:			$neutral-darkgrey2 !default;
$title-color:			$neutral-darkgrey2 !default;
$border-color: 			$lightgray !default;
$bg-color:				$lightgray1 !default;
$button-cancel-color: $neutral-darkgrey2;
$button-delete-color: $vibrant-red;

$black-color-title:     #111111;
$header-title-color:    #777777;
$background-color:      #222222;
$border-color:          #e3e7e8;
$border-color-un-title: #c3c3c3;
// ====================
// Devices
// ---
$desktop:               1200px;
$tablet-ht:             992px;		// Tablet Horizontal
$tablet-vt:             768px;		// Tablet Vertical
$mobile:                640px;
$mobile-md:             480px;
$mobile-sm:             360px;
$mobile-xs:             320px;


// ====================
// List for space around: padding, margin
// ---
$list-item: 30, 40, 50, 60;

// ====================
// Variables from SL corp

$color_1: #333;
$color_2: #555;
$color_3: #ed1e79;
$color_4: #222;
$color_5: #777;
$color_6: #000;
$color_7: #4d4d4d;
$color_8: #0c982a;
$color_9: #fff;
$color_10: grey;
$color_11: #ccc;
$color_12: #f6f6f6;
$color_13: #dd4b39;
$color_14: transparent;
$color_15: #666;
$color_16: #999;
$color_17: #eee;
$color_18: rgba(143,255,182,.7);
$color_19: #8fffb6;

$font_family_1: "Chronicle Text G1 A","Chronicle Text G1 B","Crimson Text","Times New Roman",Times,serif;
$font_family_2: "Chronicle Display A","Chronicle Display B","Crimson Text","Times New Roman",Times,serif;
$font_family_3: "Gotham A","Gotham B",Montserrat,Helvetica,Arial,sans-serif;
$font_family_4: icomoon;
$font_family_5: wheel;

$background_color_1: #fff;
$background_color_2: #8fffb6;
$background_color_3: rgba(143,255,182,.5);
$background_color_4: #f6f6f6;
$background_color_5: rgba(119,119,119,.15);
$background_color_6: rgba(119,119,119,.3);
$background_color_7: rgba(12,152,42,.15);
$background_color_8: rgba(12,152,42,.3);
$background_color_9: rgba(138,109,59,.15);
$background_color_10: rgba(138,109,59,.3);
$background_color_11: rgba(169,68,66,.15);
$background_color_12: rgba(169,68,66,.3);
$background_color_13: #29abe2;
$background_color_14: #1a8dbe;
$background_color_15: #5cff95;
$background_color_16: #777;
$background_color_17: #555;
$background_color_18: #eee;
$background_color_19: #000;
$background_color_20: rgba(0,0,0,.7);
$background_color_21: #ccc;
$background_color_22: #333;
$background_color_23: transparent;
$background_color_24: #e6e6e6;
$background_color_25: #1a1a1a;
$background_color_26: rgba(237,30,121,.4);
$background_color_27: #ddd;
$background_color_28: #f2f2f2;
$background_color_29: #999;
$background_color_30: #222;
$background_color_31: #9f9;

$border_color_1: #66afe9;
$border_color_2: 0 none;
$border_color_3: #71b504;
$border_color_4: #0070a8;
$border_color_5: #00c745;
$border_color_6: #ccc #ccc transparent transparent;
$border_top_color_1: #afd861;
$border_top_color_2: #60b49f;
$border_top_color_3: #60e281;
$border_top_color_4: #004c73;
$border_top_color_5: #458da6;
$border_top_color_6: #1A9AA8;
$border_bottom_color_1: #ddd;
$border_bottom_color_2: #eee;
$border_bottom_color_3: #ccc;
